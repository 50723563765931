<template>
  <div class="main_content align-items-center">
    <div class="top_heading">
      <div class="row justify-content-xl-between align-items-center">
        <div class="col-12 col-md-12 col-xl-5 col-xxl-5">
          <div class="heading padd_ipad">
            <h2 style="color:#39353D;">Ergo Eval Activity</h2>
            <h3 style="color:#39353D;">Program Manager Dashboard</h3>
          </div>
        </div>
        <div class="col-12 col-md-12 col-xl-7 col-xxl-7">
          <div class="align-items-center d-flex justify-content-end w-100">
            <div class="date_toolbar_main">
              <div class="bg-white date-toolbar region_bar_add">
                <div class="row me-0">
                  <div class="col-xs-12 col-md-12 col-lg-9 align-self-center">
                    <div class="">
                      <div class="bg-white date-toolbar" style="box-shadow: none">
                        <div class="row">
                          <div class="col-xs-12 col-md-12 align-self-center">
                            <div class="row">
                              <div class="col-xs-12 col-md-12 col-lg-6">
                                <div class="date">
                                  <div class="heading_5">From</div>
                                  <div class="
                                      text_black
                                      font_size_14
                                      d-flex
                                      align-items-center
                                    ">
                                    <span>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="17.5" height="17.5"
                                        viewBox="0 0 17.5 17.5">
                                        <path data-name="Path 131"
                                          d="M7.444 6.556V3m7.111 3.556V3m-8 7.111h8.889M4.778 19h12.444A1.778 1.778 0 0 0 19 17.222V6.556a1.778 1.778 0 0 0-1.778-1.778H4.778A1.778 1.778 0 0 0 3 6.556v10.666A1.778 1.778 0 0 0 4.778 19z"
                                          transform="translate(-2.25 -2.25)" style="
                                            fill: none;
                                            stroke-linecap: round;
                                            stroke-linejoin: round;
                                            stroke-width: 1.5px;
                                          " />
                                      </svg>
                                    </span>
                                    <datepicker placeholder="MM DD, YYYY" v-model="listConfig.start_date"
                                      :format="customFormatter" @selected="changeStartDate"
                                      :disabledDates="disabledFutureDates"></datepicker>
                                    <p class="min-date-value d-none">
                                      {{ listConfig.start_date | formatDate }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="col-xs-12 col-md-12 col-lg-6">
                                <div class="date todate pl_6">
                                  <div class="heading_5">To</div>
                                  <div class=" text_black font_size_14 d-flex align-items-center ">
                                    <span>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="17.5" height="17.5"
                                        viewBox="0 0 17.5 17.5">
                                        <path data-name="Path 131"
                                          d="M7.444 6.556V3m7.111 3.556V3m-8 7.111h8.889M4.778 19h12.444A1.778 1.778 0 0 0 19 17.222V6.556a1.778 1.778 0 0 0-1.778-1.778H4.778A1.778 1.778 0 0 0 3 6.556v10.666A1.778 1.778 0 0 0 4.778 19z"
                                          transform="translate(-2.25 -2.25)"
                                          style=" fill: none; stroke-linecap: round; stroke-linejoin: round; stroke-width: 1.5px; " />
                                      </svg>
                                    </span>
                                    <datepicker placeholder="MM DD, YYYY" v-model="listConfig.end_date"
                                      :format="customFormatter" @selected="changeEndDate" :disabled="isDisabled"
                                      :disabledDates="disabledDates"></datepicker>
                                    <p class="max-date-value d-none">
                                      {{ listConfig.end_date | formatDate }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-md-12 col-lg-3 align-self-center">
                    <div class="form-group for_ipad">
                      <v-select @input="filterMainData" :reduce="(region_list) => region_list.id" :options="region_list"
                        label="name" placeholder="By Region" name="Region" v-model="listConfig.regionBy"
                        v-validate="'required'" browserAutocomplete="false" autocomplete="off" />
                      <small class="error text-danger" v-show="errors.has('addUserForm.Region')">
                        {{ errors.first("addUserForm.Region") }}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <a href="javascript:" class="reset_btn" @click="resetDatesMain">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px"
                y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
                <metadata>
                  Svg Vector Icons : http://www.onlinewebfonts.com/icon
                </metadata>
                <g>
                  <path
                    d="M829.7,134.7C740.5,59,624.7,10,500,10C228.3,10,10,228.3,10,500h89.1C99.1,277.3,277.3,99.1,500,99.1c102.5,0,196,35.7,262.8,98l-75.7,71.3l267.3,49L914.3,50.1L829.7,134.7z M179.3,869.7C268.3,945.5,379.7,990,500,990c271.7,0,490-218.3,490-490h-89.1c0,222.7-178.2,400.9-400.9,400.9c-98,0-187.1-35.7-253.9-89.1l84.7-80.2l-267.3-49l40.1,267.3L179.3,869.7z" />
                </g>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="most_visited self_assesment">
      <div class="row">
        <div class="col-xl-6">
          <div class="row">
            <div class="col-md-12 col-lg-3 mt-5">
              <div class="d-flex green_card h-100 align-items-center">
                <div class="card_box padding_mini">
                  <router-link :to="{
                    name: 'dashboard_survey_reports_EE'
                  }" target="_blank">
                    <img :src="require('@/assets/images/ui-part.png')" alt="" />
                    <h3 class="green_head">Completed 1:1 Evaluations</h3>
                    <div class="flex">
                      <h2>{{ completed_ergo_eval }}</h2>
                    </div>
                    <img :src="require('@/assets/images/green_graph.png')" alt="" class="bottom_graph" />
                  </router-link>
                </div>
              </div>
            </div>
            <div class="col-lg-9 mt-5 ergo_eval_activity">
              <div class="white_card big_card pb-3">
                <div class="card_box border-bot">
                  <h2 class="i_head">Reported Discomfort by Equipment</h2>
                  <div class="tab_report toggle_switch reported">
                    <ul class="nav nav-tabs" id="myTab1" role="tablist">
                      <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="percent-tab1" data-bs-toggle="tab" data-bs-target="#percent1"
                          type="button" role="tab" aria-controls="percent" aria-selected="true">
                          %
                        </button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link" id="hash-tab1" data-bs-toggle="tab" data-bs-target="#hash1" type="button"
                          role="tab" aria-controls="hash" aria-selected="false">
                          #
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="tab-content" id="myTab1Content">
                  <div class="tab-pane fade show active" id="percent1" role="tabpanel" aria-labelledby="percent-tab1">
                    <div v-if="discomfort_by_equipment.length" class="mainscroll">
                      <div class="scrollable-section d-flex flex-wrap no-border">
                        <div v-for="(dbe, inx) in discomfort_by_equipment"
                          class="align-items-start d-flex w-50 withprogress wid_mac" :key="inx">
                          <img :src="require('@/assets/images/' + dbe.image)" alt="" />
                          <div class="
                              detailsProgress
                              justify-content-between
                              w-100
                            ">
                            <div class="
                                align-items-center
                                d-flex d-md-flex d-xxl-flex
                                justify-content-between
                                w-100
                              ">
                              <h2 class="letter_max" v-tooltip="dbe.name">{{ dbe.name }}</h2>
                              <h3>{{ dbe.percent }}%</h3>
                            </div>
                            <div class="progressBar">
                              <ul class="d-flex">
                                <li v-for="(level, indx) in dbe.levels" :class="level == '1' ? 'bg_green' : ''"
                                  :key="indx"></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else class="scrollable-section  no-border">
                      <div class="align-items-center  w-100 text-center">
                        <div class="detailsProgress justify-content-between w-100 font_drop">
                          <h2 class="no_data">No Data Found</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="hash1" role="tabpanel" aria-labelledby="hash-tab1">
                    <div class="mainscroll">
                      <div v-if="discomfort_by_equipment.length" class="scrollable-section d-flex flex-wrap no-border">
                        <div v-for="(dbe, inx) in discomfort_by_equipment"
                          class="align-items-start d-flex w-50 withprogress wid_mac" :key="inx">
                          <img :src="require('@/assets/images/' + dbe.image)" alt="" />
                          <div class="
                              detailsProgress
                              justify-content-between
                              w-100
                            ">
                            <div class="
                                align-items-center
                                d-flex d-md-flex d-xxl-flex
                                justify-content-between
                                w-100
                              ">
                              <h2 class="letter_max" :title="dbe.name">{{ dbe.name }}</h2>
                              <h3>{{ dbe.value }}</h3>
                            </div>
                            <div class="progressBar">
                              <ul class="d-flex">
                                <li v-for="(level, indx) in dbe.levels" :class="level == '1' ? 'bg_green' : ''"
                                  :key="indx"></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-else class="scrollable-section  no-border">
                        <div class="align-items-center  w-100 text-center">
                          <div class="detailsProgress justify-content-between w-100 font_drop">
                            <h2 class="no_data">No Data Found</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-4" ref="EvalActivityByRegionDiv">
            <div class="col-md-12">
              <div class="activity_region">
                <div class="row">
                  <div class="col-8 col-sm-6">
                    <h2 class="mb_20">Eval Activity by Region</h2>
                  </div>
                  <div class="col-4 col-sm-6">
                    <div class=" visited_pages align-items-baseline d-flex justify-content-end ">
                      <div class="d-none heading">
                        <div class="form-group by_min mb__10">
                          <v-select @input="filterActivityRegion" :reduce="(region_list) => region_list.id"
                            :options="region_list" label="name" placeholder="By Region" name="Region"
                            v-model="listConfig.region_regionBy" v-validate="'required'" />
                          <small class="error text-danger" v-show="errors.has('addUserForm.Region')">
                            {{ errors.first("addUserForm.Region") }}
                          </small>
                        </div>
                      </div>
                      <div v-if="hideActivityRegionDownloadBtn" class="d-flex justify-content-end ms-2 ms-sm-5">
                        <div data-html2canvas-ignore="true">
                          <a href="javascript:" data-bs-toggle="modal" data-bs-target="#download"
                            @click="prepareChart('EvalActivityByRegionDiv', 'Ergo-Activity-By-Region', '500px', '199px', 'EvalActivityByRegionHeight', activity_region)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                              <g data-name="Group 12689">
                                <path data-name="Path 114"
                                  d="M4 14.5v.875A2.625 2.625 0 0 0 6.625 18h8.75A2.625 2.625 0 0 0 18 15.375V14.5M14.5 11 11 14.5m0 0L7.5 11m3.5 3.5V4"
                                  transform="translate(-3 -3)"
                                  style=" fill: none; stroke: #a9a9a9; stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px; " />
                              </g>
                            </svg>
                          </a>
                        </div>
                        <div class="d-none dropdown calender_dropdown">
                          <a href="http://" class="ml_20 dropdown-toggle" role="button" id="dropdownMenuLink1"
                            data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="false">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                              <path data-name="Path 130"
                                d="M6.889 6.111V3m6.222 3.111V3m-7 6.222h7.778M4.556 17h10.888A1.556 1.556 0 0 0 17 15.444V6.111a1.556 1.556 0 0 0-1.556-1.556H4.556A1.556 1.556 0 0 0 3 6.111v9.333A1.556 1.556 0 0 0 4.556 17z"
                                transform="translate(-2 -2)"
                                style=" fill: none; stroke: #a9a9a9; stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px; " />
                            </svg>
                          </a>
                          <div class=" dropdown-menu px_20 py_20 calender_date for_filter "
                            aria-labelledby="dropdownMenuLink1">
                            <div class="date pl_6">
                              <div class="
                                  d-flex
                                  justify-content-between
                                  align-items-center
                                ">
                                <div class="heading_5 font_bold mb_10">
                                  From
                                </div>
                                <a href="javascript:" class="reset_btn" @click="resetDatesRegion">
                                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                    version="1.1" x="0px" y="0px" viewBox="0 0 1000 1000"
                                    enable-background="new 0 0 1000 1000" xml:space="preserve">
                                    <metadata>
                                      Svg Vector Icons :
                                      http://www.onlinewebfonts.com/icon
                                    </metadata>
                                    <g>
                                      <path
                                        d="M829.7,134.7C740.5,59,624.7,10,500,10C228.3,10,10,228.3,10,500h89.1C99.1,277.3,277.3,99.1,500,99.1c102.5,0,196,35.7,262.8,98l-75.7,71.3l267.3,49L914.3,50.1L829.7,134.7z M179.3,869.7C268.3,945.5,379.7,990,500,990c271.7,0,490-218.3,490-490h-89.1c0,222.7-178.2,400.9-400.9,400.9c-98,0-187.1-35.7-253.9-89.1l84.7-80.2l-267.3-49l40.1,267.3L179.3,869.7z" />
                                    </g>
                                  </svg>
                                </a>
                              </div>
                              <div class="
                                  text_black
                                  font_size_14
                                  border
                                  px_12
                                  py_10
                                  d-flex
                                  align-items-center
                                ">
                                <span class="mr_10">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                    <path data-name="Path 130"
                                      d="M6.889 6.111V3m6.222 3.111V3m-7 6.222h7.778M4.556 17h10.888A1.556 1.556 0 0 0 17 15.444V6.111a1.556 1.556 0 0 0-1.556-1.556H4.556A1.556 1.556 0 0 0 3 6.111v9.333A1.556 1.556 0 0 0 4.556 17z"
                                      transform="translate(-2 -2)" style="
                                        fill: none;
                                        stroke: #a9a9a9;
                                        stroke-linecap: round;
                                        stroke-linejoin: round;
                                        stroke-width: 2px;
                                      " />
                                  </svg>
                                </span>
                                <datepicker placeholder="MM DD, YYYY" v-model="listConfig.region_sdate"
                                  :format="customFormatter" @selected="changeStartDateRegion"
                                  :disabledDates="disabledFutureDates"></datepicker>
                                <p class="min-date-value d-none">
                                  {{ listConfig.region_sdate | formatDate }}
                                </p>
                              </div>
                            </div>
                            <div class="date pl_6 mt_20">
                              <div class="heading_5 font_bold mb_10">To</div>
                              <div class="
                                  text_black
                                  font_size_14
                                  border
                                  px_12
                                  py_10
                                  d-flex
                                  align-items-center
                                ">
                                <span class="mr_10">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                    <path data-name="Path 130"
                                      d="M6.889 6.111V3m6.222 3.111V3m-7 6.222h7.778M4.556 17h10.888A1.556 1.556 0 0 0 17 15.444V6.111a1.556 1.556 0 0 0-1.556-1.556H4.556A1.556 1.556 0 0 0 3 6.111v9.333A1.556 1.556 0 0 0 4.556 17z"
                                      transform="translate(-2 -2)" style="
                                        fill: none;
                                        stroke: #a9a9a9;
                                        stroke-linecap: round;
                                        stroke-linejoin: round;
                                        stroke-width: 2px;
                                      " />
                                  </svg>
                                </span>
                                <datepicker placeholder="MM DD, YYYY" v-model="listConfig.region_edate"
                                  :format="customFormatter" @selected="changeEndDateRegion" :disabled="isDisabledRegion"
                                  :disabledDates="disabledDates"></datepicker>
                                <p class="max-date-value d-none">
                                  {{ listConfig.region_edate | formatDate }}
                                </p>
                              </div>
                            </div>
                            <div class="button mt_30">
                              <a href="javascript:" @click="updateActivityRegion" class="btn btn_primary px_14">Update</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mapping mt_20 EvalActivityByRegionHeight">
                <div v-show="activity_region.length" class="row align-items-center">
                  <div class="col-md-6">
                    <div class="pieIchart">
                      <pie-chart :height="250" ref="appendPieChart" :chart-data="piechartdatacollection"></pie-chart>
                    </div>
                  </div>
                  <div class="col-md-6 align-self-start">
                    <div class="row mb_32 mb__25 justify-content-end">
                      <div class="col-9 text-end">
                        <div class="tab_report toggle_switch" data-html2canvas-ignore="true">
                          <ul class="nav nav-tabs" id="myTab2" role="tablist">
                            <li class="nav-item" role="presentation">
                              <button class="nav-link active" id="percent-tab2" data-bs-toggle="tab"
                                data-bs-target="#percent2" type="button" role="tab" aria-controls="percent"
                                aria-selected="true">
                                %
                              </button>
                            </li>
                            <li class="nav-item" role="presentation">
                              <button class="nav-link" id="hash-tab2" data-bs-toggle="tab" data-bs-target="#hash2"
                                type="button" role="tab" aria-controls="hash" aria-selected="false">
                                #
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="tab-content" id="myTab2Content">
                      <div class="tab-pane fade show active" id="percent2" role="tabpanel" aria-labelledby="percent-tab2">
                        <div class="registrant cust_scroll ergo_act">
                          <div v-for="(acr, inx) in activity_region"
                            class=" d-flex justify-content-between registrant_section " :key="inx">
                            <div class="name">
                              <h3>
                                <!-- <span v-if="inx==0" class="circle blue"></span>
                                <span v-if="inx==1" class="circle pink"></span>
                                <span v-if="inx==2" class="circle orange"></span>
                                <span v-if="inx==3" class="circle skyblue"></span>
                                <span v-if="inx==4" class="circle green"></span>
                                <span v-if="inx > 4" class="circle grey"></span> -->
                                <span class="circle" :style="'background-color:' + acr.code + ';'"></span>
                                {{ acr.name }}
                              </h3>
                            </div>
                            <div class="percent">
                              <h3>{{ acr.percent }}%</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="hash2" role="tabpanel" aria-labelledby="hash-tab2">
                        <div class="registrant cust_scroll ergo_act">
                          <div v-for="(acr, inx) in activity_region"
                            class=" d-flex justify-content-between registrant_section " :key="inx">
                            <div class="name">
                              <h3>
                                <!-- <span v-if="inx==0" class="circle blue"></span>
                                <span v-if="inx==1" class="circle pink"></span>
                                <span v-if="inx==2" class="circle orange"></span>
                                <span v-if="inx==3" class="circle skyblue"></span>
                                <span v-if="inx==4" class="circle green"></span>
                                <span v-if="inx > 4" class="circle grey"></span> -->
                                <span class="circle" :style="'background-color:' + acr.code + ';'"></span>
                                {{ acr.name }}
                              </h3>
                            </div>
                            <div class="percent">
                              <h3>{{ acr.value }}</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-show="!activity_region.length" class="row">
                  <div class="col-md-12 text-center">
                    <div class="align-items-start d-flex w-100 withprogress white_card border_radius_20">
                      <div class="detailsProgress justify-content-between w-100">
                        <div class=" align-items-center d-flex d-xxl-flex justify-content-between w-100 h_150 font_drop">
                          <h2 class="no_data">No Data Found</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6">
          <div class="row h-100">
            <div class="col-lg-12 mt-5 top-ipad">
              <div class=" border_radius_20 white_card big_card h-100 position-relative ">
                <h2 class="px-3 pt-3">Mitigation &amp; Cost Savings</h2>
                <img v-tooltip="{ ref: 'tooltipRef' }" :src="require('@/assets/images/info.png')" class="info_icon"
                  alt="" />
                <div ref="tooltipRef" class="tooltip-content">
                  <p>1. Site to 1:1 Eval = Client users count - Users count with finalized ergo eval reports</p>
                  <p>2. Self-Assessment to Eval = Users count with self assessment attempted - Users count with finalized
                    ergo eval reports.</p>
                </div>
                <!-- <img v-tooltip="
                    'Mitigation Rate = Total company ergo cost savings through employee site use, which preempts an employees need to progress from self-assessment to 1:1 ergo evaluation.'
                  " :src="require('@/assets/images/info.png')" class="info_icon" alt="" /> -->
                <hr style="background: #E2E2E2; margin-bottom: 0px; height: 2px;" />
                <div class="mitigation pad_mini border-dot px-3" style="border-color: #E2E2E2;">
                  <div class="d-flex align-items-start donut_chart">
                    <doughnut-chart :width="80" :height="80" :chart-data="mitisitedatacollection" ref="appendMitiSite">
                    </doughnut-chart>
                    <div class="
                        d-lg-block d-xxl-flex
                        justify-content-between
                        w-100
                      ">
                      <div class="miti-details">
                        <h2 v-tooltip="'Site to 1:1 Eval'" class="font_mini sml_eval">Site to 1:1 Eval</h2>
                        <div class="tab-content" id="myTabMitiSiteContent">
                          <div class="tab-pane fade show active" id="percentMitiSite" role="tabpanel"
                            aria-labelledby="percent-tabMitiSite">
                            <h3>{{ mitigation_site_percent }}%</h3>
                          </div>
                          <div class="tab-pane fade" id="hashMitiSite" role="tabpanel" aria-labelledby="hash-tabMitiSite">
                            <h3>{{ mitigation_site_count }}</h3>
                          </div>
                        </div>
                      </div>
                      <div class="tab_report toggle_switch text-end">
                        <ul id="myTabMitiSite" role="tablist" class="nav nav-tabs">
                          <li role="presentation" class="nav-item">
                            <button id="percent-tabMitiSite" data-bs-toggle="tab" data-bs-target="#percentMitiSite"
                              type="button" role="tab" aria-controls="percent" aria-selected="true"
                              class="nav-link active">
                              %
                            </button>
                          </li>
                          <li role="presentation" class="nav-item">
                            <button id="hash-tabMitiSite" data-bs-toggle="tab" data-bs-target="#hashMitiSite"
                              type="button" role="tab" aria-controls="hash" aria-selected="false" class="nav-link">
                              #
                            </button>
                          </li>
                        </ul>
                        <h3>${{ mitigation_site_cost }} USD</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="mitigationViewPermission" class="mitigation pad_mini border-dot px-3" style="border-color: #E2E2E2;">
                  <div class="d-flex align-items-start donut_chart">
                    <doughnut-chart :width="80" :height="80" :chart-data="mitiselfdatacollection" ref="appendMitiSelf">
                    </doughnut-chart>
                    <div class="
                        d-lg-block d-xxl-flex
                        justify-content-between
                        w-100
                      ">
                      <div class="miti-details">
                        <h2 v-tooltip="'Self-Assessment to Eval'" class="font_mini sml_eval">Self-Assessment to Eval</h2>
                        <div class="tab-content" id="myTabMitiSelfContent">
                          <div class="tab-pane fade show active" id="percentMitiSelf" role="tabpanel"
                            aria-labelledby="percent-tabMitiSelf">
                            <h3>{{ mitigation_selfAssessment_percent }}%</h3>
                          </div>
                          <div class="tab-pane fade" id="hashMitiSelf" role="tabpanel" aria-labelledby="hash-tabMitiSelf">
                            <h3>{{ mitigation_selfAssessment_count }}</h3>
                          </div>
                        </div>

                      </div>
                      <div class="tab_report toggle_switch text-end">
                        <ul id="myTabMitiSelf" role="tablist" class="nav nav-tabs">
                          <li role="presentation" class="nav-item">
                            <button id="percent-tabMitiSelf" data-bs-toggle="tab" data-bs-target="#percentMitiSelf"
                              type="button" role="tab" aria-controls="percent" aria-selected="true"
                              class="nav-link active">
                              %
                            </button>
                          </li>
                          <li role="presentation" class="nav-item">
                            <button id="hash-tabMitiSelf" data-bs-toggle="tab" data-bs-target="#hashMitiSelf"
                              type="button" role="tab" aria-controls="hash" aria-selected="false" class="nav-link">
                              #
                            </button>
                          </li>
                        </ul>
                        <h3>${{ mitigation_selfAssessment_cost }} USD</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else class="mitigation pad_mini border-dot px-3 grey-out-section" style="border-color: #E2E2E2;" @click="showInfoPopup">
                  <div class="d-flex align-items-start donut_chart">
                    <doughnut-chart :width="80" :height="80" :chart-data="mitiselfdatacollection" ref="appendMitiSelf">
                    </doughnut-chart>
                    <div class="
                        d-lg-block d-xxl-flex
                        justify-content-between
                        w-100
                      ">
                      <div class="miti-details">
                        <h2 class="font_mini sml_eval">Self-Assessment to Eval</h2>
                        <div class="tab-content" id="myTabMitiSelfContent">
                          <div class="tab-pane fade show active" id="percentMitiSelf" role="tabpanel"
                            aria-labelledby="percent-tabMitiSelf">
                            <h3>10%</h3>
                          </div>
                          <div class="tab-pane fade" id="hashMitiSelf" role="tabpanel" aria-labelledby="hash-tabMitiSelf">
                            <h3>$100000</h3>
                          </div>
                        </div>

                      </div>
                      <div class="tab_report toggle_switch text-end">
                        <ul id="myTabMitiSelf" role="tablist" class="nav nav-tabs">
                          <li role="presentation" class="nav-item">
                            <button id="percent-tabMitiSelf" data-bs-toggle="tab" data-bs-target="#percentMitiSelf"
                              type="button" role="tab" aria-controls="percent" aria-selected="true"
                              class="nav-link active">
                              %
                            </button>
                          </li>
                          <li role="presentation" class="nav-item">
                            <button id="hash-tabMitiSelf" data-bs-toggle="tab" data-bs-target="#hashMitiSelf"
                              type="button" role="tab" aria-controls="hash" aria-selected="false" class="nav-link">
                              #
                            </button>
                          </li>
                        </ul>
                        <h3>$100000 USD</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
    <div class="self_report">
      <div class="row">
        <div class="col-xl-8 top_mob mt-5 mt-lg-3" ref="SelfReportedDiscomfortDiv">
          <div class="white_card border_radius_20 h-100">
            <div class="activity_region px-0 border-bottom tab_report mb_18 pb_14 pb--25 i_pb_20">
              <div class="row">
                <div class="col-lg-6 col-xs-8 col-sm-8 align-self-center">
                  <h2 class="mb_20 p-3">Self-Reported Discomfort</h2>
                </div>
                <div class="col-lg-6 col-xs-4 col-sm-4 text-end align-self-center pe-5">
                  <a v-show="classDiscomfort" href="javascript:" class="reset_btn d-none" @click="resetDatesDiscomfort">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                      x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
                      <metadata>
                        Svg Vector Icons : http://www.onlinewebfonts.com/icon
                      </metadata>
                      <g>
                        <path
                          d="M829.7,134.7C740.5,59,624.7,10,500,10C228.3,10,10,228.3,10,500h89.1C99.1,277.3,277.3,99.1,500,99.1c102.5,0,196,35.7,262.8,98l-75.7,71.3l267.3,49L914.3,50.1L829.7,134.7z M179.3,869.7C268.3,945.5,379.7,990,500,990c271.7,0,490-218.3,490-490h-89.1c0,222.7-178.2,400.9-400.9,400.9c-98,0-187.1-35.7-253.9-89.1l84.7-80.2l-267.3-49l40.1,267.3L179.3,869.7z" />
                      </g>
                    </svg>
                  </a>
                  <div v-if="hideSelfReportedDownloadBtn" class="ml_24 align-items-baseline"
                    data-html2canvas-ignore="true">
                    <a href="javascript:" data-bs-toggle="modal" data-bs-target="#download"
                      @click="prepareChart('SelfReportedDiscomfortDiv', 'Ergo-Self-Reported-Discomfort', '', '', '', self_reported_discomfort)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                        <g data-name="Group 12689">
                          <path data-name="Path 114"
                            d="M4 14.5v.875A2.625 2.625 0 0 0 6.625 18h8.75A2.625 2.625 0 0 0 18 15.375V14.5M14.5 11 11 14.5m0 0L7.5 11m3.5 3.5V4"
                            transform="translate(-3 -3)"
                            style=" fill: none; stroke: #a9a9a9; stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px; " />
                        </g>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade show active" id="Unique" role="tabpanel" aria-labelledby="Unique-tab">
                <div class="activity_region mb_10 px-3">
                  <div class="row date_toolbar_main">
                    <div class="d-none col-xs-12 col-sm-12 col-md-9 col-lg-9 align-self-center date-toolbar max_w_date">
                      <div class=" d-lg-flex d-md-flex align-items-center daterange-graph date ">
                        From
                        <div class="borderdate">
                          <div class=" text_black font_size_14 d-flex align-items-center ">
                            <span>
                              <svg xmlns="http://www.w3.org/2000/svg" width="17.5" height="17.5" viewBox="0 0 17.5 17.5">
                                <path data-name="Path 131"
                                  d="M7.444 6.556V3m7.111 3.556V3m-8 7.111h8.889M4.778 19h12.444A1.778 1.778 0 0 0 19 17.222V6.556a1.778 1.778 0 0 0-1.778-1.778H4.778A1.778 1.778 0 0 0 3 6.556v10.666A1.778 1.778 0 0 0 4.778 19z"
                                  transform="translate(-2.25 -2.25)" style="
                                    fill: none;
                                    stroke-linecap: round;
                                    stroke-linejoin: round;
                                    stroke-width: 1.5px;
                                  " />
                              </svg>
                            </span>
                            <datepicker placeholder="MM DD, YYYY" v-model="listConfig.discomfort_sdate"
                              :format="customFormatter" @selected="changeStartDateDiscomfort"
                              :disabledDates="disabledFutureDates"></datepicker>
                            <p class="min-date-value d-none">
                              {{ listConfig.discomfort_sdate | formatDate }}
                            </p>
                          </div>
                        </div>
                        to
                        <div class="borderdate">
                          <div class=" text_black font_size_14 d-flex align-items-center ">
                            <span>
                              <svg xmlns="http://www.w3.org/2000/svg" width="17.5" height="17.5" viewBox="0 0 17.5 17.5">
                                <path data-name="Path 131"
                                  d="M7.444 6.556V3m7.111 3.556V3m-8 7.111h8.889M4.778 19h12.444A1.778 1.778 0 0 0 19 17.222V6.556a1.778 1.778 0 0 0-1.778-1.778H4.778A1.778 1.778 0 0 0 3 6.556v10.666A1.778 1.778 0 0 0 4.778 19z"
                                  transform="translate(-2.25 -2.25)" style="
                                    fill: none;
                                    stroke-linecap: round;
                                    stroke-linejoin: round;
                                    stroke-width: 1.5px;
                                  " />
                              </svg>
                            </span>
                            <datepicker placeholder="MM DD, YYYY" v-model="listConfig.discomfort_edate"
                              :format="customFormatter" @selected="changeEndDateDiscomfort"
                              :disabled="isDisabledDiscomfort" :disabledDates="disabledDates">
                            </datepicker>
                            <p class="max-date-value d-none">
                              {{ listConfig.discomfort_edate | formatDate }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class=" col-xs-12 col-sm-4 col-md-3 col-lg-3 align-self-center text-lg-end mobile_view ">
                      <div class="d-flex justify-content-end align-items-baseline">
                        <div class="d-none visited_pages">
                          <div class="heading min_w_next">
                            <div class="form-group by_min by_min_next">
                              <v-select @input="filterSelfDiscomfort" :reduce="(region_list) => region_list.id"
                                :options="region_list" label="name" placeholder="By Region" name="Region"
                                v-model="listConfig.discomfort_regionBy" v-validate="'required'" />
                              <small class="error text-danger" v-show="errors.has('addUserForm.Region')">
                                {{ errors.first("addUserForm.Region") }}
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="graph px-3 mt_40">
                  <div v-show="self_reported_discomfort.length" class="stadn_graph scroll-chart">
                    <bar-chart :width="600" :height="300" ref="appendStandChart"
                      :chart-data="selfdiscomfortdatacollection">
                    </bar-chart>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!self_reported_discomfort.length" class="activity_region p-3 tab_report mb_18 pb_14">
              <div class="row">
                <div class="detailsProgress justify-content-between w-100">
                  <div class=" align-items-center d-md-flex d-xxl-flex justify-content-between w-100 h_160 mobo_set">
                    <h2 class="no_data">No Data Found</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 top_mob mt-5 mt-lg-3" ref="WorkAreaAssessedDiv">
          <div class="white_card border_radius_20 h-100">
            <div class=" activity_region px-0 border-bottom tab_report mb_18 pb_14 pb--25 i_pb_20 ">
              <div class="row">
                <div class="col-9 col-sm-10 col-lg-9 align-self-center">
                  <h2 class="mb_20 p-3">Work Area Assessed</h2>
                </div>
                <div v-if="hideWorkAreaDownloadBtn" class="col-3 col-sm-2 col-lg-3 align-self-center text-center">
                  <div class="align-self-center" data-html2canvas-ignore="true">
                    <a href="javascript:" data-bs-toggle="modal" data-bs-target="#download"
                      @click="prepareChart('WorkAreaAssessedDiv', 'Ergo-Work-Area-Assessed', '', '', '', work_area_assessed)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                        <g data-name="Group 12689">
                          <path data-name="Path 114"
                            d="M4 14.5v.875A2.625 2.625 0 0 0 6.625 18h8.75A2.625 2.625 0 0 0 18 15.375V14.5M14.5 11 11 14.5m0 0L7.5 11m3.5 3.5V4"
                            transform="translate(-3 -3)"
                            style=" fill: none; stroke: #a9a9a9; stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px; " />
                        </g>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade show active" id="Unique" role="tabpanel" aria-labelledby="Unique-tab">
                <div class="activity_region mb_10">
                  <div class="row">
                    <div class=" col-sm-12 align-self-center text-lg-end px-4 py-md-3 py-lg-0 ">
                      <div class=" d-flex justify-content-lg-end justify-content-end align-items-baseline ">
                        <div class="d-none visited_pages">
                          <div class="heading">
                            <div class="form-group mb-0 by_min">
                              <v-select @input="filterWorkArea" :reduce="(region_list) => region_list.id"
                                :options="region_list" label="name" placeholder="By Region" name="Region"
                                v-model="listConfig.workArea_regionBy" v-validate="'required'" />
                              <small class="error text-danger" v-show="errors.has('addUserForm.Region')">
                                {{ errors.first("addUserForm.Region") }}
                              </small>
                            </div>
                          </div>
                        </div>
                        <div class="d-none dropdown calender_dropdown">
                          <a href="javascript:void(0)" id="dropdownMenuLink2" data-bs-toggle="dropdown"
                            aria-expanded="false" class="ml_20 dropdown-toggle" role="button"
                            data-bs-auto-close="false">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                              <path data-name="Path 130"
                                d="M6.889 6.111V3m6.222 3.111V3m-7 6.222h7.778M4.556 17h10.888A1.556 1.556 0 0 0 17 15.444V6.111a1.556 1.556 0 0 0-1.556-1.556H4.556A1.556 1.556 0 0 0 3 6.111v9.333A1.556 1.556 0 0 0 4.556 17z"
                                transform="translate(-2 -2)"
                                style=" fill: none; stroke: #a9a9a9; stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px; " />
                              <path data-name="Path 130"
                                d="M6.889 6.111V3m6.222 3.111V3m-7 6.222h7.778M4.556 17h10.888A1.556 1.556 0 0 0 17 15.444V6.111a1.556 1.556 0 0 0-1.556-1.556H4.556A1.556 1.556 0 0 0 3 6.111v9.333A1.556 1.556 0 0 0 4.556 17z"
                                transform="translate(-2 -2)"
                                style=" fill: none; stroke: #a9a9a9; stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px; " />
                            </svg>
                          </a>
                          <div class=" dropdown-menu px_20 py_20 calender_date for_filter "
                            aria-labelledby="dropdownMenuLink2">
                            <div class="date pl_6">
                              <div class=" d-flex justify-content-between align-items-center ">
                                <div class="heading_5 font_bold mb_10">
                                  From
                                </div>
                                <a href="javascript:void(0)" class="reset_btn" @click="resetDatesWorkArea">
                                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                    version="1.1" x="0px" y="0px" viewBox="0 0 1000 1000"
                                    enable-background="new 0 0 1000 1000" xml:space="preserve">
                                    <metadata>
                                      Svg Vector Icons :
                                      http://www.onlinewebfonts.com/icon
                                    </metadata>
                                    <g>
                                      <path
                                        d="M829.7,134.7C740.5,59,624.7,10,500,10C228.3,10,10,228.3,10,500h89.1C99.1,277.3,277.3,99.1,500,99.1c102.5,0,196,35.7,262.8,98l-75.7,71.3l267.3,49L914.3,50.1L829.7,134.7z M179.3,869.7C268.3,945.5,379.7,990,500,990c271.7,0,490-218.3,490-490h-89.1c0,222.7-178.2,400.9-400.9,400.9c-98,0-187.1-35.7-253.9-89.1l84.7-80.2l-267.3-49l40.1,267.3L179.3,869.7z" />
                                    </g>
                                  </svg>
                                </a>
                              </div>
                              <div class=" text_black font_size_14 border px_12 py_10 d-flex align-items-center ">
                                <span class="mr_10">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                    <path data-name="Path 130"
                                      d="M6.889 6.111V3m6.222 3.111V3m-7 6.222h7.778M4.556 17h10.888A1.556 1.556 0 0 0 17 15.444V6.111a1.556 1.556 0 0 0-1.556-1.556H4.556A1.556 1.556 0 0 0 3 6.111v9.333A1.556 1.556 0 0 0 4.556 17z"
                                      transform="translate(-2 -2)" style="
                                        fill: none;
                                        stroke: #a9a9a9;
                                        stroke-linecap: round;
                                        stroke-linejoin: round;
                                        stroke-width: 2px;
                                      " />
                                  </svg>
                                </span>
                                <datepicker placeholder="MM DD, YYYY" v-model="listConfig.workArea_sdate"
                                  :format="customFormatter" @selected="changeStartDateWorkArea"
                                  :disabledDates="disabledFutureDates"></datepicker>
                                <p class="min-date-value d-none">
                                  {{
                                    listConfig.workArea_sdate | formatDate
                                  }}
                                </p>
                              </div>
                            </div>
                            <div class="date pl_6 mt_20">
                              <div class="heading_5 font_bold mb_10">
                                To
                              </div>
                              <div class=" text_black font_size_14 border px_12 py_10 d-flex align-items-center ">
                                <span class="mr_10">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                    <path data-name="Path 130"
                                      d="M6.889 6.111V3m6.222 3.111V3m-7 6.222h7.778M4.556 17h10.888A1.556 1.556 0 0 0 17 15.444V6.111a1.556 1.556 0 0 0-1.556-1.556H4.556A1.556 1.556 0 0 0 3 6.111v9.333A1.556 1.556 0 0 0 4.556 17z"
                                      transform="translate(-2 -2)" style="
                                        fill: none;
                                        stroke: #a9a9a9;
                                        stroke-linecap: round;
                                        stroke-linejoin: round;
                                        stroke-width: 2px;
                                      " />
                                  </svg>
                                </span>
                                <datepicker placeholder="MM DD, YYYY" v-model="listConfig.workArea_edate"
                                  :format="customFormatter" @selected="changeEndDateWorkArea"
                                  :disabled="isDisabledWorkArea" :disabledDates="disabledDates"></datepicker>
                                <p class="max-date-value d-none">
                                  {{
                                    listConfig.workArea_edate | formatDate
                                  }}
                                </p>
                              </div>
                            </div>
                            <div class="button mt_30">
                              <a href="javascript:" @click="updateWorkArea" class="btn btn_primary px_14">Update</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="graph mt_40 px-3">
                  <div v-show="work_area_assessed.length" class="bar_graph">
                    <bar-chart ref="appendWorkArea" :chart-data="workareadatacollection"></bar-chart>
                  </div>
                </div>
              </div>
            </div>
            <div v-show="!work_area_assessed.length" class=" activity_region p-3 tab_report mb_18 pb_14 ">
              <div class="row">
                <div class="detailsProgress justify-content-between w-100">
                  <div class=" align-items-center d-flex d-xxl-flex justify-content-between w-100 h_160 mobo_set">
                    <h2 class="no_data">No Data Found</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="self_report">
      <div class="row">
        <div class="col-xl-12 mt-5 mt-lg-5 mt-xl-3" ref="EquipmentRecommendationsDiv">
          <div class="white_card border_radius_20">
            <div class="activity_region p-3 border-bottom tab_report mb_18 pb_14">
              <div class="row">
                <div class="col-8 col-xs-8 col-sm-8 align-self-center">
                  <h2 class="mb_20">Equipment Recommendations</h2>
                </div>
                <div class="col-4 col-xs-4 col-sm-4 text-end">
                  <a v-show="classEquipment" href="javascript:" class="d-none reset_btn" @click="resetDatesEquipment">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                      x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
                      <metadata>
                        Svg Vector Icons : http://www.onlinewebfonts.com/icon
                      </metadata>
                      <g>
                        <path
                          d="M829.7,134.7C740.5,59,624.7,10,500,10C228.3,10,10,228.3,10,500h89.1C99.1,277.3,277.3,99.1,500,99.1c102.5,0,196,35.7,262.8,98l-75.7,71.3l267.3,49L914.3,50.1L829.7,134.7z M179.3,869.7C268.3,945.5,379.7,990,500,990c271.7,0,490-218.3,490-490h-89.1c0,222.7-178.2,400.9-400.9,400.9c-98,0-187.1-35.7-253.9-89.1l84.7-80.2l-267.3-49l40.1,267.3L179.3,869.7z" />
                      </g>
                    </svg>
                  </a>
                  <div v-if="hideEquipmentDownloadBtn" class="align-self-baseline ml_24" data-html2canvas-ignore="true">
                    <a href="javascript:" data-bs-toggle="modal" data-bs-target="#download"
                      @click="prepareChart('EquipmentRecommendationsDiv', 'Ergo-Equipment-Recommendations', '', '', '', equipment_recommendations)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                        <g data-name="Group 12689">
                          <path data-name="Path 114"
                            d="M4 14.5v.875A2.625 2.625 0 0 0 6.625 18h8.75A2.625 2.625 0 0 0 18 15.375V14.5M14.5 11 11 14.5m0 0L7.5 11m3.5 3.5V4"
                            transform="translate(-3 -3)"
                            style=" fill: none; stroke: #a9a9a9; stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px; " />
                        </g>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade show active" id="Unique" role="tabpanel" aria-labelledby="Unique-tab">
                <div class="activity_region mb_10 px-3">
                  <div class="row date_toolbar_main">
                    <div class="d-none col-xs-12 col-sm-12 col-md-9 col-lg-9 align-self-center date-toolbar max_w_date">
                      <div class=" d-lg-flex d-md-flex align-items-center daterange-graph date ">
                        From
                        <div class="borderdate">
                          <div class=" text_black font_size_14 d-flex align-items-center ">
                            <span>
                              <svg xmlns="http://www.w3.org/2000/svg" width="17.5" height="17.5" viewBox="0 0 17.5 17.5">
                                <path data-name="Path 131"
                                  d="M7.444 6.556V3m7.111 3.556V3m-8 7.111h8.889M4.778 19h12.444A1.778 1.778 0 0 0 19 17.222V6.556a1.778 1.778 0 0 0-1.778-1.778H4.778A1.778 1.778 0 0 0 3 6.556v10.666A1.778 1.778 0 0 0 4.778 19z"
                                  transform="translate(-2.25 -2.25)" style="
                                    fill: none;
                                    stroke-linecap: round;
                                    stroke-linejoin: round;
                                    stroke-width: 1.5px;
                                  " />
                              </svg>
                            </span>
                            <datepicker placeholder="MM DD, YYYY" v-model="listConfig.equipment_sdate"
                              :format="customFormatter" @selected="changeStartDateEquipment"
                              :disabledDates="disabledFutureDates"></datepicker>
                            <p class="min-date-value d-none">
                              {{ listConfig.equipment_sdate | formatDate }}
                            </p>
                          </div>
                        </div>
                        to
                        <div class="borderdate">
                          <div class=" text_black font_size_14 d-flex align-items-center ">
                            <span>
                              <svg xmlns="http://www.w3.org/2000/svg" width="17.5" height="17.5" viewBox="0 0 17.5 17.5">
                                <path data-name="Path 131"
                                  d="M7.444 6.556V3m7.111 3.556V3m-8 7.111h8.889M4.778 19h12.444A1.778 1.778 0 0 0 19 17.222V6.556a1.778 1.778 0 0 0-1.778-1.778H4.778A1.778 1.778 0 0 0 3 6.556v10.666A1.778 1.778 0 0 0 4.778 19z"
                                  transform="translate(-2.25 -2.25)" style="
                                    fill: none;
                                    stroke-linecap: round;
                                    stroke-linejoin: round;
                                    stroke-width: 1.5px;
                                  " />
                              </svg>
                            </span>
                            <datepicker placeholder="MM DD, YYYY" v-model="listConfig.equipment_edate"
                              :format="customFormatter" @selected="changeEndDateEquipment" :disabled="isDisabledEquipment"
                              :disabledDates="disabledDates">
                            </datepicker>
                            <p class="max-date-value d-none">
                              {{ listConfig.equipment_edate | formatDate }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class=" col-xs-12 col-sm-4 col-md-3 col-lg-3 align-self-center text-lg-end mobile_view ">
                      <div class="d-flex justify-content-end align-items-baseline">
                        <div class="d-none visited_pages">
                          <div class="heading min_w_next">
                            <div class="form-group by_min by_min_next">
                              <v-select @input="filterEquipmentData" :reduce="(region_list) => region_list.id"
                                :options="region_list" label="name" placeholder="By Region" name="Region"
                                v-model="listConfig.equipment_regionBy" v-validate="'required'" />
                              <small class="error text-danger" v-show="errors.has('addUserForm.Region')">
                                {{ errors.first("addUserForm.Region") }}
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-show="equipment_recommendations.length" class="graph scroll-chart px-3">
                  <bar-chart :width="600" :height="300" ref="appendEquipment"
                    :chart-data="equipmentdatacollection"></bar-chart>
                </div>
              </div>
              <div class="tab-pane fade" id="Revisits" role="tabpanel" aria-labelledby="Revisits-tab">
                <div class="activity_region mb_10">
                  <div class="row">
                    <div class="col-md-7 align-self-center">
                      <div class="d-flex align-items-center">
                        <div class="new_user">
                          <a href="#" class="">
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"
                              class="mr_10">
                              <g data-name="Group 12685" transform="translate(-1031 -251)">
                                <rect data-name="Rectangle 2213" width="25" height="25" rx="12.5"
                                  transform="translate(1031 251)" style="fill: #29cb97" />
                                <g data-name="Group 12684">
                                  <path data-name="Path 125"
                                    d="M9.714 8.573v1.194A3.429 3.429 0 0 0 5.143 13H4a4.571 4.571 0 0 1 5.714-4.428zm-1.143-.716A3.429 3.429 0 1 1 12 4.429a3.428 3.428 0 0 1-3.429 3.428zm0-1.143a2.286 2.286 0 1 0-2.285-2.285 2.285 2.285 0 0 0 2.285 2.285zM12 10.143V8.429h1.143v1.714h1.714v1.143h-1.714V13H12v-1.714h-1.714v-1.143z"
                                    transform="translate(1034 256.999)" style="fill: #fff" />
                                </g>
                              </g>
                            </svg>
                            New Users
                          </a>
                        </div>
                        <div class="value ml_20 font_size_20 font_bold">
                          <a href="#" class="">
                            50
                            <svg xmlns="http://www.w3.org/2000/svg" width="12.828" height="15.271"
                              viewBox="0 0 12.828 15.271">
                              <path data-name="Path 79" d="m5 8 5-5m0 0 5 5m-5-5v12.857"
                                transform="translate(-3.586 -1.586)" style="
                                  fill: none;
                                  stroke: #29cb97;
                                  stroke-linecap: round;
                                  stroke-linejoin: round;
                                  stroke-width: 2px;
                                " />
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3 align-self-center text-lg-end">
                      <div class="visited_pages">
                        <div class="heading">
                          <div class="form-group mb-0">
                            <v-select v-model="selected" :options="['By Region', 'Client User']"
                              placeholder="By Region" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2 text-end align-self-center">
                      <div class="d-flex justify-content-center">
                        <a href="http://">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                            <g data-name="Group 12689">
                              <path data-name="Path 114"
                                d="M4 14.5v.875A2.625 2.625 0 0 0 6.625 18h8.75A2.625 2.625 0 0 0 18 15.375V14.5M14.5 11 11 14.5m0 0L7.5 11m3.5 3.5V4"
                                transform="translate(-3 -3)" style="
                                  fill: none;
                                  stroke: #a9a9a9;
                                  stroke-linecap: round;
                                  stroke-linejoin: round;
                                  stroke-width: 2px;
                                " />
                            </g>
                          </svg>
                        </a>
                        <a href="http://" class="ml_20">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                            <path data-name="Path 130"
                              d="M6.889 6.111V3m6.222 3.111V3m-7 6.222h7.778M4.556 17h10.888A1.556 1.556 0 0 0 17 15.444V6.111a1.556 1.556 0 0 0-1.556-1.556H4.556A1.556 1.556 0 0 0 3 6.111v9.333A1.556 1.556 0 0 0 4.556 17z"
                              transform="translate(-2 -2)" style="
                                fill: none;
                                stroke: #a9a9a9;
                                stroke-linecap: round;
                                stroke-linejoin: round;
                                stroke-width: 2px;
                              " />
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="graph mb_34">
                  <img :src="require('@/assets/images/line_graph.png')" alt="graph" />
                </div>
              </div>
            </div>
            <div v-if="!equipment_recommendations.length" class="activity_region p-3 tab_report mb_18 pb_14">
              <div class="row">
                <div class="detailsProgress justify-content-between w-100">
                  <div class=" align-items-center d-md-flex d-xxl-flex justify-content-between w-100 i_pad_set">
                    <h2 class="no_data">No Data Found</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="self_report">
      <div class="row">
        <div class="col-md-12 mt-5 mt-lg-1">
          <div class="white_card border_radius_20">
            <div class="activity_region p-3 border-bottom tab_report mb_18 pb_14">
              <div class="row">
                <div class="col-sm-8 align-self-center">
                  <h2 class="mb_20">Pending 1:1 Eval Requests</h2>
                </div>
                <div class="col-xs-12 col-sm-4 text-end" style="display: none">
                  <a href="javascript:" class="reset_btn">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                      x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
                      <metadata>
                        Svg Vector Icons : http://www.onlinewebfonts.com/icon
                      </metadata>
                      <g>
                        <path
                          d="M829.7,134.7C740.5,59,624.7,10,500,10C228.3,10,10,228.3,10,500h89.1C99.1,277.3,277.3,99.1,500,99.1c102.5,0,196,35.7,262.8,98l-75.7,71.3l267.3,49L914.3,50.1L829.7,134.7z M179.3,869.7C268.3,945.5,379.7,990,500,990c271.7,0,490-218.3,490-490h-89.1c0,222.7-178.2,400.9-400.9,400.9c-98,0-187.1-35.7-253.9-89.1l84.7-80.2l-267.3-49l40.1,267.3L179.3,869.7z">
                        </path>
                      </g>
                    </svg>
                  </a>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="table_card" style="box-shadow: none; padding: 20px 0 !important">
                    <div id="datatable_wrapper" class="dataTables_wrapper no-footer add_marg">
                      <div class="dataTables_length float-none float-xl-start text-center text-lg-start"
                        id="datatable_length">
                        <label>Reports
                          <select name="datatable_length" aria-controls="datatable" class="" v-model.number="itemsPerPage"
                            @change="getPendingRequest">
                            <option>10</option>
                            <option>25</option>
                            <option>50</option>
                            <option>100</option>
                            <option>500</option>
                          </select>
                          entries</label>
                      </div>
                      <div
                        class="align-items-center d-flex justify-content-center justify-content-md-center justify-content-lg-end flex-wrap flex-lg-nowrap  align-items-md-baseline">
                        <div id="datatable_filter"
                          class="dataTables_filter mr_20 mr_mini fil_align mt-0 mt-lg-2 mt-xl-0 text-center">
                          <h1 v-if="pendingCheckbox.length" class="updatestatus text-center mt-3 mt-lg-0 me-0">Update
                            Status </h1>
                          <label class="float-none" v-else><input type="search" class="" placeholder="Search"
                              aria-controls="datatable" v-model="listConfig.search_string"
                              v-on:keyup.enter="getPendingRequest" />
                            <a v-if="listConfig.search_string" href="javascript:void(0)" @click="getPendingRequest"
                              class="search_icon slty_up">
                              <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#00"
                                class="bi bi-search" viewBox="0 0 16 16">
                                <path
                                  d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                              </svg>
                            </a>
                            <a href="javascript:void(0)" v-if="listConfig.search_string" @click="
                              listConfig.search_string = null;
                            getPendingRequest();
                            " class="crossicon">
                              X
                            </a>
                          </label>
                        </div>
                        <div class="region_select status_box new_status status_select i_pad_pro mb-5 mr_20 mr_mini">
                          <v-select v-if="pendingCheckbox.length" :reduce="(update_status_list) => update_status_list.id"
                            :options="update_status_list" label="name" placeholder="All Status"
                            v-model="listConfig.search_status" @input="updateBulkStatus($event)" />
                          <v-select v-else :reduce="(status_list) => status_list.id" :options="status_list" label="name"
                            placeholder="All Status" v-model="listConfig.search_status"
                            @input="updateBulkStatus($event)" />
                        </div>
                        <div v-if="pendingCheckbox.length && oldSelectedCheckbox != 'Hidden'"
                          class="region_select status_box new_status status_select mb-5 mt-3 mt-lg-0 ps-2 ps-lg-0">
                          <a href="javascript:" class="comman_icons " @click="updateBulkStatus('Hidden')">
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16.975" viewBox="0 0 17 16.975">
                              <path id="Path_107" data-name="Path 107"
                                d="M11.431,15.437a7.874,7.874,0,0,1-8.974-5.364A7.836,7.836,0,0,1,3.685,7.693m4.6.714a2.358,2.358,0,0,1,3.335,3.335M8.289,8.406l3.334,3.334M8.291,8.407,5.705,5.821m5.92,5.92,2.586,2.586M2.884,3,5.705,5.821m0,0a7.862,7.862,0,0,1,11.752,4.252,7.879,7.879,0,0,1-3.247,4.253m0,0,2.821,2.821"
                                transform="translate(-1.457 -1.586)" fill="none" stroke="#a9a9a9" stroke-linecap="round"
                                stroke-linejoin="round" stroke-width="2" />
                            </svg>
                            <span class="ml_10"><b>Hide</b></span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="table-responsive table_audit_log pending_table">
                      <table class="table table-bordered table dataTable no-footer">
                        <thead>
                          <tr>
                            <!-- <th style="min-width: 10px" class="sort pending_check">
                              <div class="form_check_box d-flex align-items-center mt-1">
                                <input v-if="data_length == 0" type="checkbox" aria-label="..." class="form-check-input ms-0" disabled />
                                <input v-else type="checkbox" v-model="selectAll" @change="checkSelected($event)" aria-label="..." id="flexCheckDefault"
                                  class="form-check-input ms-0" />
                                <label for="flexCheckDefault" class=""> </label>
                              </div>
                            </th> -->
                            <th scope="col" class="sort req_sml">
                              <SortIcons :lable="'REQUEST ON'" :listConfig="listConfig" :sortBy="'created_at'"
                                @sorting="sorting($event)" />
                            </th>
                            <th scope="col" class="sort">
                              <SortIcons :lable="'First Name'" :listConfig="listConfig" :sortBy="'first_name'"
                                @sorting="sorting($event)" />
                            </th>
                            <th scope="col" class="sort">
                              <SortIcons :lable="'Last Name'" :listConfig="listConfig" :sortBy="'last_name'"
                                @sorting="sorting($event)" />
                            </th>
                            <th scope="col" class="sort pending_col">
                              <SortIcons :lable="'Email Address'" :listConfig="listConfig" :sortBy="'user_email'"
                                @sorting="sorting($event)" />
                            </th>
                            <th scope="col" class="sort req_sml">
                              <SortIcons :lable="'Location'" :listConfig="listConfig" :sortBy="'region'"
                                @sorting="sorting($event)" />
                            </th>
                            <th scope="col" class="sort sml_eval_col">
                              <SortIcons :lable="'COMFORT'" :listConfig="listConfig" :sortBy="'experiencing_discomfort'"
                                @sorting="sorting($event)" />
                            </th>
                            <th scope="col" class="sort sml_eval_col move_icon">
                              <SortIcons :lable="'STATUS'" :listConfig="listConfig" :sortBy="'status'"
                                @sorting="sorting($event)" />
                              <svg style="margin-left: 10px;" v-tooltip="'Pending, Scheduled, No Reply, Declined, Hidden, Complete, In Progress, No Show & Rescheduled'
                                " xmlns="http://www.w3.org/2000/svg" data-name="Group 13109" width="16" height="16"
                                viewBox="0 0 14 18.346">
                                <path data-name="Path 9426" d="M0 0h14v14H0z" style="fill: none"></path>
                                <path data-name="Path 9427"
                                  d="M9 16a7 7 0 1 1 7-7 7 7 0 0 1-7 7zm0-1.4A5.6 5.6 0 1 0 3.4 9 5.6 5.6 0 0 0 9 14.6zm-.7-9.1h1.4v1.4H8.3zm0 2.8h1.4v4.2H8.3z"
                                  transform="translate(-2 -1.654)" style="fill: rgb(109, 109, 109)"></path>
                              </svg>
                            </th>
                            <th style="text-align:center;" scope="col" class="sml_eval_col">ACTION</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(penReq, indx) in pending_request" :key="indx">
                            <!-- <td style="min-width: 10px">
                              <div class="form_check_box d-flex align-items-center">
                                <input type="checkbox" v-model="pendingCheckbox" :value="penReq.id" aria-label="..." :id="'flexCheckDefault' + indx" class="form-check-input ms-0" @change="checkSelected($event)" />
                                <label :for="'flexCheckDefault' + indx" class="">
                                </label>
                              </div>
                            </td> -->
                            <td>
                              <p class="">{{ penReq.request_on }}</p>
                            </td>
                            <td>
                              <p>{{ penReq.first_name }}</p>
                            </td>
                            <td>
                              <p>{{ penReq.last_name }}</p>
                            </td>
                            <td>
                              <p>{{ penReq.email }}</p>
                            </td>
                            <td>
                              <p>{{ penReq.location }}</p>
                            </td>
                            <td>
                              <p>{{ penReq.comfort }}</p>
                            </td>
                            <td>
                              <p>{{ penReq.status }}</p>
                            </td>
                            <td style="text-align:center;">
                              <div class="edit_btn mr_le">
                                <p>
                                  <a href="javascript:" @click="showRequestDataSet(penReq.dataset,penReq.first_name+' '+penReq.last_name)" aria-current="page"
                                    title="Data Set" class=" router-link-exact-active router-link-active"
                                    data-bs-toggle="modal" data-bs-target="#dataset">
                                    <img class="view-icon" :src="require('@/assets/images/dashboard/View_Icon.svg')"
                                      alt="img" />
                                  </a>
                                  <!-- <a href="javascript:" @click="viewRequestData(penReq)" class="view-dshboad-btn ergo_table_view"
                                    title="View" data-bs-toggle="modal" data-bs-target="#viewRequestData">
                                    <img class="view-icon" :src="require('@/assets/images/dashboard/View_Icon.svg')"
                                      alt="img" />
                                  </a> -->
                                  <a href="javascript:" @click="allComments(penReq)"
                                    class="view-dshboad-btn ergo_table_btn" title="Add Client Note" data-bs-toggle="modal"
                                    data-bs-target="#commentModal">
                                    <img style="top: 0px" class="edit-icon"
                                      :src="require('@/assets/images/dashboard/note.svg')" alt="img" />
                                  </a>
                                </p>
                              </div>
                            </td>
                          </tr>
                          <tr v-if="data_length == 0">
                            <td colspan="9" class="text-center">
                              No Records found
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <p class="float-lg-start text-sm-center">
                      {{ positionText }}
                    </p>
                    <VPagination v-if="data_length" class="dataTables_paginate" :totalPages="pages.length"
                      :perPage="itemsPerPage" :currentPage.sync="currentPage" @pagechanged="onPageChange" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade box_up viewRequestData" id="viewRequestData" tabindex="-1" aria-labelledby="downloadLabel"
      aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="downloadLabel">
              <h3 class="heading_2"><strong> Eval Requests </strong></h3>
            </h5>
            <button type="button" class="btn-close viewRequestDataBtn" data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <div class="modal-body details_eval">
            <div class="row">
              <div class="col-xs-12 col-md-12">
                <p>
                  <strong> REQUEST ON : </strong>
                  {{ pendingRequestModal.request_on }}
                </p>
              </div>
              <div class="col-xs-12 col-md-12 text-start">
                <p>
                  <strong> STATUS : </strong>
                  {{ pendingRequestModal.status }}
                </p>
              </div>
              <div class="col-xs-12 col-md-12">
                <p><strong> FIRST NAME : </strong>
                  {{ pendingRequestModal.first_name }}</p>
              </div>
              <div class="col-xs-12 col-md-12">
                <p><strong> LAST NAME : </strong>
                  {{ pendingRequestModal.last_name }}</p>
              </div>
              <div class="col-xs-12 col-md-12 text-start">
                <p>
                  <strong> Contact Number : </strong>
                  {{ pendingRequestModal.contact_number }}
                </p>
              </div>
              <div class="col-xs-12 col-md-12">
                <p>
                  <strong> COMFORT : </strong>
                  {{ pendingRequestModal.comfort }}
                </p>
              </div>
              <div class="col-xs-12 col-md-12 text-start">
                <p>
                  <strong> Discomfort Type : </strong>
                  {{ pendingRequestModal.discomfort_type }}
                </p>
              </div>
              <div class="col-xs-12 col-md-12">
                <p>
                  <strong> EMAIL ADDRESS : </strong>
                  {{ pendingRequestModal.email }}
                </p>
              </div>
              <div class="col-xs-12 col-md-12">
                <p>
                  <strong> LOCATION : </strong>
                  {{ pendingRequestModal.location }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal box_up fade commentModal" id="commentModal" tabindex="-1" aria-labelledby="downloadLabel"
      aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="downloadLabel">
              <h3 class="heading_1">Eval Request Comments</h3>
            </h5>
            <button type="button" class="btn-close commentModalBtn" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div v-if="allCommentArr.length">
              <div class="row mb-3">

                <div class="table table-responsive cmt_eva_table cust_scroll">
                  <table class="table" width="100%">
                    <tbody>
                      <tr>
                        <th style="width:30%;">Name</th>
                        <th style="width:30%;">Email</th>
                        <th style="width:40%;">Comment</th>
                      </tr>
                      <tr v-for="(comment, indx) in allCommentArr" :key="indx">
                        <td valign="middle" style="width:30%;">{{ comment.user_name }}</td>
                        <td valign="middle" style="width:30%;">{{ comment.user_email }}</td>
                        <td valign="middle" style="width:40%;">{{ comment.comments }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

              </div>
            </div>
            <div v-if="!allCommentArr.length" class="row">
              <div class="col-xs-12 col-md-12">
                <h2 class="text-center no_data mb-5">There are no comments</h2>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12 col-md-12">
                <div class="mb-3 form-group maxheight">
                  <div class="form-group">
                    <textarea class="form-control" aria-describedby="emailHelp" placeholder="Write your comment here"
                      v-model="add_comment" v-validate="'required'" v-on:keyup="countdown" maxlength="1000" />
                    <label class="text-end w-100">{{ remainingCount }} characters remaining</label>
                  </div>
                </div>
                <div v-if="add_comment.length" class="text-center mt_30">
                  <button :disabled="isButtonDisabled" @click="addComment" class="btn btn_primary mr_10" type="button">
                    Add Comment
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal box_up fade download_modal" id="download" tabindex="-1" aria-labelledby="downloadLabel"
      aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="downloadLabel">
              <h3 class="heading_2">Download</h3>
            </h5>
            <button type="button" class="btn-close downloadBtn" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-around">
              <div class="col-md-3">
                <div class="image_upload text-center downloadDocumentClass disabled">
                  <a href="javascript:" class="text-center" @click="enableDownloadBtn('document')">
                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="42" viewBox="0 0 36 42" class="d-block">
                      <path data-name="Path 122"
                        d="M13.889 23h13.333m-13.333 8.889h13.333M31.667 43H9.444A4.444 4.444 0 0 1 5 38.556V7.444A4.444 4.444 0 0 1 9.444 3h12.414a2.222 2.222 0 0 1 1.571.651L35.46 15.682a2.222 2.222 0 0 1 .651 1.571v21.3A4.444 4.444 0 0 1 31.667 43z"
                        transform="translate(-4 -2)"
                        style=" fill:none; stroke-linecap:round; stroke-linejoin:round; stroke-width:2px " />
                    </svg>
                    Download as a document
                  </a>
                </div>
              </div>
              <div class="col-md-3">
                <div class="image_upload text-center downloadImageClass">
                  <a href="javascript:" class="text-center" @click="enableDownloadBtn('image')">
                    <svg xmlns="http://www.w3.org/2000/svg" width="38.891" height="38.063" viewBox="0 0 38.891 38.063"
                      class="d-block">
                      <path data-name="Path 143"
                        d="m4 31.047 10.336-10.336a4.508 4.508 0 0 1 6.374 0l10.337 10.336m-4.508-4.508 3.575-3.575a4.508 4.508 0 0 1 6.374 0l3.575 3.575M26.539 13.016h.023M8.508 40.063h27.047a4.508 4.508 0 0 0 4.508-4.508V8.508A4.508 4.508 0 0 0 35.555 4H8.508A4.508 4.508 0 0 0 4 8.508v27.047a4.508 4.508 0 0 0 4.508 4.508z"
                        transform="translate(-2.586 -3)" style="
                          fill: none;
                          stroke-linecap: round;
                          stroke-linejoin: round;
                          stroke-width: 2px;
                        " />
                    </svg>
                    Download as an image
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer border-top-0 mx-auto mb_50">
            <button type="button" class="btn btn_primary_light mr_30" data-bs-dismiss="modal"> Close </button>
            <button type="button" class="btn btn_primary sml_download" @click="downloadChart()">Download</button>
          </div>
        </div>
      </div>
    </div>
    <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
    <DatasetModal v-bind:downloadMessage="downloadMessage" v-bind:dashboard="dashboard" v-bind:dataSet="dataSet" v-bind:isEmptyDataSet="isEmptyDataSet" ref="datasetModal"></DatasetModal>
    <InfoPopup ref="infoPopup" :heading="popupHeading" :message="popupMessage" @close="closePopup"></InfoPopup>
    </div>
</template>
<script>
import $ from "jquery";
import moment from "moment";
import "vue-select/dist/vue-select.css";
import Datepicker from "vuejs-datepicker";
import PieChart from "../../components/charts/PieChart.js";
import BarChart from "../../components/charts/BarChart.js";
import DoughnutChart from "../../components/charts/DoughnutChart.js";
import SortIcons from "@/components/SortingIcons";
import VPagination from "@/components/VPagination";
import ConfirmDialogue from "@/components/ConfirmDialogue";
import DatasetModal from "@/components/DatasetModal";
// import jsPDF from 'jspdf';
import html2Canvas from 'html2canvas';
import { utils, writeFileXLSX } from 'xlsx';
import commonFunction from "../../mixin/commonFunction";
import commonService from "../../services/commonService";
import InfoPopup from "@/components/InfoPopup";

export default {
  name: "ergo_eval_activity",
  mixins: [commonFunction],
  components: {
    Datepicker,
    PieChart,
    BarChart,
    DoughnutChart,
    VPagination,
    SortIcons,
    ConfirmDialogue,
    DatasetModal,
    InfoPopup
  },
  data() {
    return {
      downloadMessage: 'Dataset',
      dashboard:'Ergo Eval Activity',
      show: false,
      btnText: "",
      setChartWidth: '',
      doughnut_chart_options: {
        tooltips: { enabled: false },
        hover: { mode: null },
      },
      chart_options: {
        legend: { display: false },
        cornerRadius: 7,
      },
      barChartOptions: {
        legend: { display: false },
        responsive: window.innerWidth < 767 ? false : true,
        cornerRadius: 7,
      },
      mitisitedatacollection: {
        datasets: [
          {
            backgroundColor: ["#4880FF", "#4880FF4D"],
            data: [0, 100],
          },
        ],
      },
      mitiselfdatacollection: {
        datasets: [
          {
            backgroundColor: ["#4880FF", "#4880FF4D"],
            data: [0, 100],
          },
        ],
      },
      piechartdatacollection: {
        labels: "",
        datasets: [
          {
            backgroundColor: [],
            data: [],
          },
        ],
      },
      workareadatacollection: {
        labels: [],
        datasets: [
          {
            data: [],
            label: "Work Area Assessed",
            backgroundColor: ["#4880FF", "#29CB96", "#FF9560", "#FDC102", "#F3C695",],
            maxBarThickness: 40,
          },
        ],
      },
      selfdiscomfortdatacollection: {
        labels: [],
        datasets: [
          {
            data: [],
            label: "Self-Reported Discomfort",
            backgroundColor: [],
            maxBarThickness: 40,
          },
        ],
      },
      equipmentdatacollection: {
        labels: [],
        datasets: [
          {
            data: [],
            label: "Equipment Recommendations",
            backgroundColor: [],
            maxBarThickness: 40,
          },
        ],
      },
      region_list: [{
        id: 'ALL',
        name: 'ALL',
      }],
      status_list: commonService.requestReportStatus(),
      update_status_list: commonService.requestReportUpdateStatus(),
      // status_list: [
      //   {
      //     id: null,
      //     name: "All Status",
      //   }, {
      //     id: "Pending",
      //     name: "Pending",
      //   }, {
      //     id: "Scheduled",
      //     name: "Scheduled",
      //   }, {
      //     id: "No Reply",
      //     name: "No Reply",
      //   }, {
      //     id: "Declined",
      //     name: "Declined",
      //   }, {
      //     id: "Hidden",
      //     name: "Hidden",
      //   }
      // ],
      // update_status_list: [
      //   {
      //     id: "Pending",
      //     name: "Pending",
      //   }, {
      //     id: "Scheduled",
      //     name: "Scheduled",
      //   }, {
      //     id: "No Reply",
      //     name: "No Reply",
      //   }, {
      //     id: "Declined",
      //     name: "Declined",
      //   },
      // ],
      disabledDates: {
        to: new Date(Date.now() - 8640000),
      },
      disabledFutureDates: {
        from: new Date(Date.now()),
      },
      currentPage: 1,
      itemsPerPage: 10,
      listConfig: {
        offset: (this.currentPage - 1) * this.itemsPerPage,
        limit: this.itemsPerPage,
        sortBy: "created_at",
        order: "DESC",
        search_string: null,
        search_status: null,

        typeOfAssessment: "Ergo Eval",
        //Main filter...
        start_date: null,
        end_date: null,
        regionBy: null,
        //Work Area Assessed filter...
        workArea_sdate: null,
        workArea_edate: null,
        workArea_regionBy: null,
        //Activity Region filter...
        region_sdate: null,
        region_edate: null,
        region_regionBy: null,
        //Self-Reported Discomfort filter...
        discomfort_sdate: null,
        discomfort_edate: null,
        discomfort_regionBy: null,
        //Equipment Recommendations filter...
        equipment_sdate: null,
        equipment_edate: null,
        equipment_regionBy: null,
      },
      data_length: 0,
      selected: "",
      isDisabled: "",
      isDisabledWorkArea: "",
      isDisabledRegion: "",
      isDisabledDiscomfort: "",
      isDisabledEquipment: "",
      classDiscomfort: false,
      classEquipment: false,

      completed_ergo_eval: 0,
      discomfort_by_equipment: [],
      mitigation_site_cost: 0,
      mitigation_site_count: 0,
      mitigation_site_percent: 0,
      mitigation_selfAssessment_cost: 0,
      mitigation_selfAssessment_count: 0,
      mitigation_selfAssessment_percent: 0,
      mitigation_cost_saving: 0,
      work_area_assessed_HomeCSV: [],
      work_area_assessed_LabCSV: [],
      work_area_assessed_OfficeCSV: [],
      work_area_assessed_AnywhereCSV: [],
      work_area_assessed: [],
      activity_region_CSV: [],
      activity_region: [],
      self_reported_discomfortCSV: [],
      self_reported_discomfort: [],
      equipment_recommendationsCSV: [],
      equipment_recommendations: [],
      pending_request: [],
      pendingCheckbox: [],
      dataArr: [],
      pendingCheckboxArr: {
        status: null,
        checkboxes: [],
      },
      pendingRequestModal: {
        name: null,
        email: null,
        status: null,
        comfort: null,
        location: null,
        request_on: null,
        contact_number: null,
        discomfort_type: null,
      },
      allCommentArr: [],
      add_comment: '',
      limitmaxCount: 1000,
      maxCount: 1000,
      remainingCount: 1000,
      hasError: false,
      commentArr: [],
      addCommentArr: {
        schedule_id: null,
        comments: null,
      },
      oldSelectedCheckbox: null,
      downloadRef: '',
      downloadType: 'image',
      downloadImageName: '',
      downloadMaxHeight: '',
      downloadMinHeight: '',
      downloadHeightClass: '',
      downloadCSVData: '',
      rowsData: [],
      hideActivityRegionDownloadBtn: true,
      hideWorkAreaDownloadBtn: true,
      hideSelfReportedDownloadBtn: true,
      hideEquipmentDownloadBtn: true,
      dataSet: [],
      isEmptyDataSet: true,
      isButtonDisabled: false,
      previousStartDate: null,
      mitigationViewPermission: false,
      isPopupVisible: false,
      popupHeading: "Information", 
      popupMessage: "Popup Information!",
    };
  },
  computed: {
    selectAll: {
      get: function () {
        return this.pending_request
          ? this.pendingCheckbox.length == this.pending_request.length
          : false;
      },
      set: function (value) {
        var pendingCheckbox = [];
        if (value) {
          this.pending_request.forEach(function (penReq) {
            pendingCheckbox.push(penReq.id);
          });
        }
        this.pendingCheckbox = pendingCheckbox;
        this.listConfig.search_status = "";
      },
    },
    positionText: function () {
      var endIndex = this.currentPage * this.itemsPerPage,
        startIndex = (this.currentPage - 1) * this.itemsPerPage + 1;
      if (this.data_length == 0) {
        startIndex = 0;
      }
      return (
        "Showing " +
        startIndex +
        " to " +
        (endIndex > this.data_length ? this.data_length : endIndex) +
        " of " +
        this.data_length
      );
    },
    pages() {
      if (this.data_length <= this.itemsPerPage) {
        return [1];
      }
      let pages_array = [
        ...Array(Math.ceil(this.data_length / this.itemsPerPage)).keys(),
      ].map((e) => e + 1);
      return pages_array;
    },
  },
  watch: {
    currentPage(newValue) {
      this.listConfig.offset = (newValue - 1) * this.itemsPerPage;
      this.getPendingRequestData();
    },
  },
  destroyed() {
    this.$parent.employeeDashboard = false;
  },
  beforeMount() {
    if (localStorage.getItem("userToken")) {
      // Store for check permission
      let res = this.$store.dispatch("auth/getAuthUser");
      res
        .then((value) => {
          let allPermission = value.user.roles.all_permission;
          if (allPermission.includes("dashboard-pages-self-assessment-view")) {
            this.mitigationViewPermission = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.tokenExpired();
    }
  },
  mounted() {
    document.title = "PBErgo - " + this.$router.currentRoute.meta.page_title;
    this.$parent.employeeDashboard = true;
    // this.getDashboardData();

    this.listConfig = {
      offset: (this.currentPage - 1) * this.itemsPerPage,
      limit: this.itemsPerPage,
      sortBy: "created_at",
      order: "DESC",
      search_string: null,
      search_status: null,

      typeOfAssessment: "Ergo Eval",
      //Main filter...
      start_date: null,
      end_date: null,
      regionBy: null,
      //Work Area Assessed filter...
      workArea_sdate: null,
      workArea_edate: null,
      workArea_regionBy: null,
      //Activity Region filter...
      region_sdate: null,
      region_edate: null,
      region_regionBy: null,
      //Self-Reported Discomfort filter...
      discomfort_sdate: null,
      discomfort_edate: null,
      discomfort_regionBy: null,
      //Equipment Recommendations filter...
      equipment_sdate: null,
      equipment_edate: null,
      equipment_regionBy: null,
    };
    //Get all regions from Client data...
    var clientData = this.$store.getters["auth/authUser"];
    var clientRegions = clientData.client.client_regions.replace(/[^a-zA-Z,/ ]/g, "");
    var clientRegionsArr = clientRegions.split(",");
    for (let i = 0; i < clientRegionsArr.length; i++) {
      this.region_list.push({ "id": clientRegionsArr[i], "name": clientRegionsArr[i] });
    }

    this.filterMainData();
  },
  methods: {
    showInfoPopup(){
      this.popupHeading = "Mitigation & Cost Savings";
      this.popupMessage = `For more information about Self-Assessments and the full Ergo-Ally Solution, please contact us at <a href="mailto:info@pbergo.com">info@pbergo.com</a>`;
      this.$refs.infoPopup.showPopup();
    },
    closePopup() {
      this.isPopupVisible = false;
    },
    prepareChart(ref, imageName, maxHeight, minHeight, heightClass, csvData) {
      this.downloadRef = ref;
      this.downloadImageName = imageName;
      this.downloadMaxHeight = maxHeight;
      this.downloadMinHeight = minHeight;
      this.downloadHeightClass = heightClass;
      this.downloadCSVData = csvData;
      this.enableDownloadBtn('image');
    },
    enableDownloadBtn(btnType) {
      if (btnType == 'image') {
        $('.downloadImageClass').removeClass('disabled');
        $('.downloadDocumentClass').addClass('disabled');
      }
      else if (btnType == 'document') {
        $('.downloadDocumentClass').removeClass('disabled');
        $('.downloadImageClass').addClass('disabled');
      }
      this.downloadType = btnType;
    },
    async downloadChart() {
      var _this = this;
      _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
      //Audit log entry  
      var sectionName = '';
        switch(_this.downloadRef) {
          case 'EvalActivityByRegionDiv':
            sectionName = 'Eval Activity by Region';
            break;
          case 'WorkAreaAssessedDiv':
            sectionName = 'Work Area Assessed';
            break;
          case 'SelfReportedDiscomfortDiv':
            sectionName = 'Self-Reported Discomfort';
            break;
          case 'EquipmentRecommendationsDiv':
            sectionName = 'Equipment Recommendations';
            break;
        } 
        var requestData = {'section': sectionName,'dashboard':'Ergo Eval Activity'};
        console.log(requestData);
        let config = {
          method: "post",
          url: process.env.VUE_APP_API_URL + "/api/audit-log-for-download",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
          },
          data: this.encodeAPIRequestWithImages(requestData),
        };
        this.axios(config)
        .then(({ data }) => {
          console.log(data.message);
        });

      if (_this.downloadHeightClass != '') {
        $('.cust_scroll').css({ 'overflow': 'unset' });
        $('.' + _this.downloadHeightClass).css({ 'height': _this.downloadMaxHeight });
      }
      $('.white_card').css({ 'box-shadow': 'unset' });
      const el = _this.$refs[_this.downloadRef];
      const options = {
        type: "dataURL",
        scrollX: 0,
        scrollY: -window.scrollY
      };
      const printCanvas = await html2Canvas(el, options);
      setTimeout(() => {
        const link = document.createElement("a");
        if (_this.downloadType == 'image') {
          link.setAttribute("download", _this.downloadImageName + '.png');
          link.setAttribute(
            "href",
            printCanvas
              .toDataURL("image/png")
              .replace("image/png", "image/octet-stream")
          );
          link.click();
        }
        else if (_this.downloadType == 'document') {
          var xlsxSheetData1 = ''; var xlsxSheetName1 = '';
          var xlsxSheetData2 = ''; var xlsxSheetName2 = '';
          var xlsxSheetData3 = ''; var xlsxSheetName3 = '';
          var xlsxSheetData4 = ''; var xlsxSheetName4 = '';
          var xlsxSheetData5 = ''; var xlsxSheetName5 = '';
          var xlsxSheetData6 = ''; var xlsxSheetName6 = '';
          var xlsxSheetData7 = ''; var xlsxSheetName7 = '';
          var xlsxSheetData8 = ''; var xlsxSheetName8 = '';
          var xlsxSheetData9 = ''; var xlsxSheetName9 = '';
          var xlsxSheetData10 = ''; var xlsxSheetName10 = '';
          var xlsxSheetData11 = ''; var xlsxSheetName11 = '';
          var xlsxSheetData12 = ''; var xlsxSheetName12 = '';
          var xlsxSheetData13 = ''; var xlsxSheetName13 = '';
          var xlsxSheetData14 = ''; var xlsxSheetName14 = '';
          var xlsxSheetData15 = ''; var xlsxSheetName15 = '';
          var xlsxSheetData16 = ''; var xlsxSheetName16 = '';
          var xlsxSheetData17 = ''; var xlsxSheetName17 = '';
          var xlsxSheetData18 = ''; var xlsxSheetName18 = '';
          var xlsxSheetData19 = ''; var xlsxSheetName19 = '';
          var xlsxSheetData20 = ''; var xlsxSheetName20 = '';
          var xlsxSheetData21 = ''; var xlsxSheetName21 = '';
          var xlsxSheetData22 = ''; var xlsxSheetName22 = '';
          var xlsxSheetData23 = ''; var xlsxSheetName23 = '';
          var xlsxSheetData24 = ''; var xlsxSheetName24 = '';
          var xlsxSheetData25 = ''; var xlsxSheetName25 = '';
          var xlsxSheetData26 = ''; var xlsxSheetName26 = '';
          var xlsxSheetData27 = ''; var xlsxSheetName27 = '';
          var xlsxSheetData28 = ''; var xlsxSheetName28 = '';
          var xlsxSheetData29 = ''; var xlsxSheetName29 = '';
          var xlsxSheetData30 = ''; var xlsxSheetName30 = '';
          if (_this.downloadRef == 'EvalActivityByRegionDiv') {
            if (_this.activity_region_CSV.length) {
              _this.activity_region_CSV.forEach(function (item, indx) {
                if (indx == 0) {
                  xlsxSheetName1 = item[0].Region + ' Region';
                  xlsxSheetData1 = utils.json_to_sheet(item);
                }
                else if (indx == 1) {
                  xlsxSheetName2 = item[0].Region + ' Region';
                  xlsxSheetData2 = utils.json_to_sheet(item);
                }
                else if (indx == 2) {
                  xlsxSheetName3 = item[0].Region + ' Region';
                  xlsxSheetData3 = utils.json_to_sheet(item);
                }
                else if (indx == 3) {
                  xlsxSheetName4 = item[0].Region + ' Region';
                  xlsxSheetData4 = utils.json_to_sheet(item);
                }
                else if (indx == 4) {
                  xlsxSheetName5 = item[0].Region + ' Region';
                  xlsxSheetData5 = utils.json_to_sheet(item);
                }
                else if (indx == 5) {
                  xlsxSheetName6 = item[0].Region + ' Region';
                  xlsxSheetData6 = utils.json_to_sheet(item);
                }
                else if (indx == 6) {
                  xlsxSheetName7 = item[0].Region + ' Region';
                  xlsxSheetData7 = utils.json_to_sheet(item);
                }
                else if (indx == 7) {
                  xlsxSheetName8 = item[0].Region + ' Region';
                  xlsxSheetData8 = utils.json_to_sheet(item);
                }
                else if (indx == 8) {
                  xlsxSheetName9 = item[0].Region + ' Region';
                  xlsxSheetData9 = utils.json_to_sheet(item);
                }
                else if (indx == 9) {
                  xlsxSheetName10 = item[0].Region + ' Region';
                  xlsxSheetData10 = utils.json_to_sheet(item);
                }
                else if (indx == 10) {
                  xlsxSheetName11 = item[0].Region + ' Region';
                  xlsxSheetData11 = utils.json_to_sheet(item);
                }
                else if (indx == 11) {
                  xlsxSheetName12 = item[0].Region + ' Region';
                  xlsxSheetData12 = utils.json_to_sheet(item);
                }
                else if (indx == 12) {
                  xlsxSheetName13 = item[0].Region + ' Region';
                  xlsxSheetData13 = utils.json_to_sheet(item);
                }
                else if (indx == 13) {
                  xlsxSheetName14 = item[0].Region + ' Region';
                  xlsxSheetData14 = utils.json_to_sheet(item);
                }
                else if (indx == 14) {
                  xlsxSheetName15 = item[0].Region + ' Region';
                  xlsxSheetData15 = utils.json_to_sheet(item);
                }
                else if (indx == 15) {
                  xlsxSheetName16 = item[0].Region + ' Region';
                  xlsxSheetData16 = utils.json_to_sheet(item);
                }
                else if (indx == 16) {
                  xlsxSheetName17 = item[0].Region + ' Region';
                  xlsxSheetData17 = utils.json_to_sheet(item);
                }
                else if (indx == 17) {
                  xlsxSheetName18 = item[0].Region + ' Region';
                  xlsxSheetData18 = utils.json_to_sheet(item);
                }
                else if (indx == 18) {
                  xlsxSheetName19 = item[0].Region + ' Region';
                  xlsxSheetData19 = utils.json_to_sheet(item);
                }
                else if (indx == 19) {
                  xlsxSheetName20 = item[0].Region + ' Region';
                  xlsxSheetData20 = utils.json_to_sheet(item);
                }
                else if (indx == 20) {
                  xlsxSheetName21 = item[0].Region + ' Region';
                  xlsxSheetData21 = utils.json_to_sheet(item);
                }
                else if (indx == 21) {
                  xlsxSheetName22 = item[0].Region + ' Region';
                  xlsxSheetData22 = utils.json_to_sheet(item);
                }
                else if (indx == 22) {
                  xlsxSheetName23 = item[0].Region + ' Region';
                  xlsxSheetData23 = utils.json_to_sheet(item);
                }
                else if (indx == 23) {
                  xlsxSheetName24 = item[0].Region + ' Region';
                  xlsxSheetData24 = utils.json_to_sheet(item);
                }
                else if (indx == 24) {
                  xlsxSheetName25 = item[0].Region + ' Region';
                  xlsxSheetData25 = utils.json_to_sheet(item);
                }
                else if (indx == 25) {
                  xlsxSheetName26 = item[0].Region + ' Region';
                  xlsxSheetData26 = utils.json_to_sheet(item);
                }
                else if (indx == 26) {
                  xlsxSheetName27 = item[0].Region + ' Region';
                  xlsxSheetData27 = utils.json_to_sheet(item);
                }
                else if (indx == 27) {
                  xlsxSheetName28 = item[0].Region + ' Region';
                  xlsxSheetData28 = utils.json_to_sheet(item);
                }
                else if (indx == 28) {
                  xlsxSheetName29 = item[0].Region + ' Region';
                  xlsxSheetData29 = utils.json_to_sheet(item);
                }
                else if (indx == 29) {
                  xlsxSheetName30 = item[0].Region + ' Region';
                  xlsxSheetData30 = utils.json_to_sheet(item);
                }
              });
            }
          }
          else if (_this.downloadRef == 'WorkAreaAssessedDiv') {
            if (_this.work_area_assessed_HomeCSV.length) {
              xlsxSheetName1 = 'Work Area Assessed Home';
              xlsxSheetData1 = utils.json_to_sheet(_this.work_area_assessed_HomeCSV);
            }
            if (_this.work_area_assessed_LabCSV.length) {
              xlsxSheetName2 = 'Work Area Assessed Lab';
              xlsxSheetData2 = utils.json_to_sheet(_this.work_area_assessed_LabCSV);
            }
            if (_this.work_area_assessed_OfficeCSV.length) {
              xlsxSheetName3 = 'Work Area Assessed Office';
              xlsxSheetData3 = utils.json_to_sheet(_this.work_area_assessed_OfficeCSV);
            }
            if (_this.work_area_assessed_AnywhereCSV.length) {
              xlsxSheetName4 = 'Work Area Assessed Anywhere';
              xlsxSheetData4 = utils.json_to_sheet(_this.work_area_assessed_AnywhereCSV);
            }
          }
          else if (_this.downloadRef == 'SelfReportedDiscomfortDiv') {
            if (_this.self_reported_discomfortCSV.length) {
              xlsxSheetName1 = 'Self-Reported Discomforts';
              xlsxSheetData1 = utils.json_to_sheet(_this.self_reported_discomfortCSV);
            }
          }
          else if (_this.downloadRef == 'EquipmentRecommendationsDiv') {
            if (_this.equipment_recommendationsCSV.length) {
              xlsxSheetName1 = 'Equipment Recommendations';
              xlsxSheetData1 = utils.json_to_sheet(_this.equipment_recommendationsCSV);
            }
          }
          // A workbook is the name given to an Excel file
          var wb = utils.book_new(); // make Workbook of Excel
          // add Worksheet to Workbook // Workbook contains one or more worksheets
          if (xlsxSheetData1) {
            utils.book_append_sheet(wb, xlsxSheetData1, xlsxSheetName1);
          }
          if (xlsxSheetData2) {
            utils.book_append_sheet(wb, xlsxSheetData2, xlsxSheetName2);
          }
          if (xlsxSheetData3) {
            utils.book_append_sheet(wb, xlsxSheetData3, xlsxSheetName3);
          }
          if (xlsxSheetData4) {
            utils.book_append_sheet(wb, xlsxSheetData4, xlsxSheetName4);
          }
          if (xlsxSheetData5) {
            utils.book_append_sheet(wb, xlsxSheetData5, xlsxSheetName5);
          }
          if (xlsxSheetData6) {
            utils.book_append_sheet(wb, xlsxSheetData6, xlsxSheetName6);
          }
          if (xlsxSheetData7) {
            utils.book_append_sheet(wb, xlsxSheetData7, xlsxSheetName7);
          }
          if (xlsxSheetData8) {
            utils.book_append_sheet(wb, xlsxSheetData8, xlsxSheetName8);
          }
          if (xlsxSheetData9) {
            utils.book_append_sheet(wb, xlsxSheetData9, xlsxSheetName9);
          }
          if (xlsxSheetData10) {
            utils.book_append_sheet(wb, xlsxSheetData10, xlsxSheetName10);
          }
          if (xlsxSheetData11) {
            utils.book_append_sheet(wb, xlsxSheetData11, xlsxSheetName11);
          }
          if (xlsxSheetData12) {
            utils.book_append_sheet(wb, xlsxSheetData12, xlsxSheetName12);
          }
          if (xlsxSheetData13) {
            utils.book_append_sheet(wb, xlsxSheetData13, xlsxSheetName13);
          }
          if (xlsxSheetData14) {
            utils.book_append_sheet(wb, xlsxSheetData14, xlsxSheetName14);
          }
          if (xlsxSheetData15) {
            utils.book_append_sheet(wb, xlsxSheetData15, xlsxSheetName15);
          }
          if (xlsxSheetData16) {
            utils.book_append_sheet(wb, xlsxSheetData16, xlsxSheetName16);
          }
          if (xlsxSheetData17) {
            utils.book_append_sheet(wb, xlsxSheetData17, xlsxSheetName17);
          }
          if (xlsxSheetData18) {
            utils.book_append_sheet(wb, xlsxSheetData18, xlsxSheetName18);
          }
          if (xlsxSheetData19) {
            utils.book_append_sheet(wb, xlsxSheetData19, xlsxSheetName19);
          }
          if (xlsxSheetData20) {
            utils.book_append_sheet(wb, xlsxSheetData20, xlsxSheetName20);
          }
          if (xlsxSheetData21) {
            utils.book_append_sheet(wb, xlsxSheetData21, xlsxSheetName21);
          }
          if (xlsxSheetData22) {
            utils.book_append_sheet(wb, xlsxSheetData22, xlsxSheetName22);
          }
          if (xlsxSheetData23) {
            utils.book_append_sheet(wb, xlsxSheetData23, xlsxSheetName23);
          }
          if (xlsxSheetData24) {
            utils.book_append_sheet(wb, xlsxSheetData24, xlsxSheetName24);
          }
          if (xlsxSheetData25) {
            utils.book_append_sheet(wb, xlsxSheetData25, xlsxSheetName25);
          }
          if (xlsxSheetData26) {
            utils.book_append_sheet(wb, xlsxSheetData26, xlsxSheetName26);
          }
          if (xlsxSheetData27) {
            utils.book_append_sheet(wb, xlsxSheetData27, xlsxSheetName27);
          }
          if (xlsxSheetData28) {
            utils.book_append_sheet(wb, xlsxSheetData28, xlsxSheetName28);
          }
          if (xlsxSheetData29) {
            utils.book_append_sheet(wb, xlsxSheetData29, xlsxSheetName29);
          }
          if (xlsxSheetData30) {
            utils.book_append_sheet(wb, xlsxSheetData30, xlsxSheetName30);
          }
          // export Excel file
          if (wb.SheetNames.length) {
            writeFileXLSX(wb, _this.downloadImageName + '.xlsx') // name of the file is 'book.xlsx'
          }
          ///////////// Commented PDF Code //////////////
          // //Adjust width and height...
          // var topMargin = 20;
          // var leftMargin = 10;
          // var imgWidth =  (printCanvas.width * 60) / 240;
          // var imgHeight = (printCanvas.height * 60) / 240;
          // if(imgWidth < 100){
          //   leftMargin = 50;
          // }
          // else if(imgWidth < 130 && imgWidth > 100){
          //   leftMargin = 30;
          // }
          // else if(imgWidth < 160 && imgWidth > 130){
          //   leftMargin = 20;
          // }
          // //jsPDF changes...
          // var pdf = new jsPDF('p', 'mm', 'a4');
          // pdf.addImage(printCanvas, 'png', leftMargin, topMargin, imgWidth, imgHeight); // 2: 19
          // pdf.save(_this.downloadImageName+`${$('.pdf-title').text()}.pdf`);
        }
        if (_this.downloadHeightClass != '') {
          $('.cust_scroll').css({ 'overflow': 'auto' });
          $('.' + _this.downloadHeightClass).css({ 'height': _this.downloadMinHeight });
        }
        $('.white_card').css({ 'box-shadow': '5px 10px 20px #f198361a' });
        _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
        document.getElementsByClassName("downloadBtn")[0].click();
      }, 100);
    },
    exportToCsv(filename, rows) {
      var processRow = function (row) {
        var finalVal = '';
        for (var j = 0; j < row.length; j++) {
          var innerValue = row[j] === null ? '' : row[j].toString();
          if (row[j] instanceof Date) {
            innerValue = row[j].toLocaleString();
          }
          var result = innerValue.replace(/"/g, '""');
          if (result.search(/("|,|\n)/g) >= 0)
            result = '"' + result + '"';
          if (j > 0)
            finalVal += ',';
          finalVal += result;
        }
        return finalVal + '\n';
      };
      var csvFile = '';
      for (var i = 0; i < rows.length; i++) {
        csvFile += processRow(rows[i]);
      }
      var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
      if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, filename);
      }
      else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", filename);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },
    updateWorkArea() {
      document.getElementById("dropdownMenuLink2").click();
      this.filterWorkArea();
    },
    updateActivityRegion() {
      document.getElementById("dropdownMenuLink1").click();
      this.filterActivityRegion();
    },
    checkSelected(event) {
      if (event.target.checked) {
        this.listConfig.search_status = "";
      } else {
        if (this.pendingCheckbox.length == 0) {
          this.listConfig.search_status = this.oldSelectedCheckbox;
        }
      }
    },
    async updateBulkStatus($event) {
      if (this.pendingCheckbox.length && $event != null) {
        const confirmUpdate = await this.$refs.confirmDialogue.show({
          title: "Update Status",
          message: "Are you sure you want to update status?",
          okButton: "Update",
        });
        if (confirmUpdate) {
          this.dataArr = [];
          this.pendingCheckboxArr.status = $event;
          this.pendingCheckboxArr.checkboxes = this.pendingCheckbox;
          this.dataArr.push(this.pendingCheckboxArr);
          if (localStorage.getItem("userToken")) {
            var _this = this;
            _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
            let config = {
              method: "post",
              url: process.env.VUE_APP_API_URL + "/api/pending-status-bulk-edit",
              headers: {
                Authorization: `Bearer ${window.localStorage.getItem(
                  "userToken"
                )}`,
              },
              data: this.dataArr,
            };
            _this
              .axios(config)
              .then(({ data }) => {
                if (data.success) {
                  _this.$toast.success(data.message, {
                    position: "top-right",
                  });
                } else {
                  _this.$toast.error(data.message, {
                    position: "top-right",
                  });
                }
                _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
              })
              .catch(() => {
                //
              });
          } else {
            this.tokenExpired();
          }
        }
      }
      setTimeout(() => {
        this.pendingCheckbox = [];
        this.getPendingRequest();
      }, 100);
    },
    viewRequestData(penReq) {
      this.pendingRequestModal.first_name = penReq.first_name;
      this.pendingRequestModal.last_name = penReq.last_name;
      this.pendingRequestModal.email = penReq.email;
      this.pendingRequestModal.status = penReq.status;
      this.pendingRequestModal.comfort = penReq.comfort;
      this.pendingRequestModal.location = penReq.location;
      this.pendingRequestModal.request_on = penReq.request_on;
      this.pendingRequestModal.contact_number = penReq.contact_number;
      this.pendingRequestModal.discomfort_type = penReq.discomfort_type;
    },
    allComments(penReq) {
      this.isButtonDisabled = false;
      this.add_comment = '';
      this.remainingCount = 1000;
      this.allCommentArr = [];
      this.fetchAllComments(penReq);
    },
    fetchAllComments(penReq) {
      if (penReq.id) {
        this.commentArr = [];
        this.addCommentArr.schedule_id = penReq.id;
        this.commentArr.push(this.addCommentArr);
        if (localStorage.getItem("userToken")) {
          var _this = this;
          let config = {
            method: "post",
            url: process.env.VUE_APP_API_URL + "/api/pending-status-all-comment",
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                "userToken"
              )}`,
            },
            data: { request_data: this.encodeAPIRequestWithImages(this.commentArr) },
          };
          _this
            .axios(config)
            .then(({ data }) => {
              if (data.success) {
                var decodedJson = _this.decodeAPIResponse(data.records.data);
                this.allCommentArr = decodedJson;
              }
            })
            .catch(() => {
              //
            });
        } else {
          this.tokenExpired();
        }
      }
    },
    addComment() {
      if (this.add_comment.length) {
        this.isButtonDisabled = true;
        this.commentArr = [];
        this.addCommentArr.comments = this.add_comment;
        this.commentArr.push(this.addCommentArr);
        if (localStorage.getItem("userToken")) {
          var _this = this;
          _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
          let config = {
            method: "post",
            url: process.env.VUE_APP_API_URL + "/api/pending-status-add-comment",
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                "userToken"
              )}`,
            },
            data: { request_data: this.encodeAPIRequestWithImages(this.commentArr) },
          };
          _this
            .axios(config)
            .then(({ data }) => {
              if (data.success) {
                _this.$toast.success(data.message, {
                  position: "top-right",
                });
                document.getElementsByClassName("commentModalBtn")[0].click();
              } else {
                _this.$toast.error(data.message, {
                  position: "top-right",
                });
              }
              _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
            })
            .catch(({ response }) => {
              _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
              if (response.status == 401) {
                _this.tokenExpired();
              } else {
                _this.$toast.error(response.data.error, {
                  position: "top-right",
                  duration: 5000,
                });
              }
            });
        } else {
          this.tokenExpired();
        }
      }
    },
    countdown: function () {
      this.remainingCount = this.maxCount - this.add_comment.length;
      this.hasError = this.remainingCount < 0;
      if (this.hasError) {
        console.log('Haserror:', this.hasError);
        return;
      }
    },
    onPageChange(page) {
      this.currentPage = page;
    },
    sorting($event) {
      this.listConfig.sortBy == $event.sortBy;
      this.listConfig.order == $event.order;
      this.getPendingRequestData();
    },
    getPendingRequest() {
      this.currentPage = 1;
      this.listConfig.limit = this.itemsPerPage;
      this.getPendingRequestData();
    },
    getPendingRequestData() {
      if (localStorage.getItem("userToken")) {
        var _this = this;
        _this.oldSelectedCheckbox = this.listConfig.search_status;
        _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
        let config = {
          method: "post",
          url: process.env.VUE_APP_API_URL + "/api/pending-request",
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
          },
          data: { request_data: _this.encodeAPIRequestWithImages(_this.listConfig) },
        };
        _this
          .axios(config)
          .then(({ data }) => {
            //Pending 1:1 Eval Requests...
            var decodedJson = _this.decodeAPIResponse(data.data);

            _this.pending_request = decodedJson.pending_request;
            _this.data_length = decodedJson.pending_request_count;
            _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          })
          .catch(() => {
            //
          });
      } else {
        this.tokenExpired();
      }
    },
    resetDatesMain() {
      // if ((this.listConfig.end_date != "" && this.listConfig.end_date != null) || this.listConfig.regionBy != "") {
      this.listConfig.start_date = "";
      this.listConfig.end_date = "";
      this.listConfig.regionBy = "";
      this.listConfig.workArea_sdate = "";
      this.listConfig.workArea_edate = "";
      this.listConfig.workArea_regionBy = "";
      this.listConfig.region_sdate = "";
      this.listConfig.region_edate = "";
      this.listConfig.region_regionBy = "";
      this.listConfig.discomfort_sdate = "";
      this.listConfig.discomfort_edate = "";
      this.listConfig.discomfort_regionBy = "";
      this.listConfig.equipment_sdate = "";
      this.listConfig.equipment_edate = "";
      this.listConfig.equipment_regionBy = "";
      this.isDisabled = true;
      this.isDisabledWorkArea = true;
      this.isDisabledRegion = true;
      this.isDisabledDiscomfort = true;
      this.isDisabledEquipment = true;

      this.classEquipment = false;
      this.classDiscomfort = false;
      this.filterMainData();
      // this.getPendingRequest();
      // }
    },
    resetDatesWorkArea() {
      this.listConfig.workArea_sdate = "";
      this.listConfig.workArea_edate = "";
      this.listConfig.workArea_regionBy = "";
      this.isDisabledWorkArea = true;
      this.updateWorkArea();
    },
    resetDatesRegion() {
      this.listConfig.region_sdate = "";
      this.listConfig.region_edate = "";
      this.listConfig.region_regionBy = "";
      this.isDisabledRegion = true;
      this.updateActivityRegion();
    },
    resetDatesDiscomfort() {
      this.listConfig.discomfort_sdate = "";
      this.listConfig.discomfort_edate = "";
      this.listConfig.discomfort_regionBy = "";
      this.classDiscomfort = false;
      this.isDisabledDiscomfort = true;
      this.filterSelfDiscomfort();
    },
    resetDatesEquipment() {
      this.listConfig.equipment_sdate = "";
      this.listConfig.equipment_edate = "";
      this.listConfig.equipment_regionBy = "";
      this.classEquipment = false;
      this.isDisabledEquipment = true;
      this.filterEquipmentData();
    },
    filterMainData() {
      //Mitigation cost,completed evaluations
      this.getMitigationCostData();
      //Activity Region filter...
      this.filterActivityRegion();
      //Self-Reported Discomfort filter...
      this.filterSelfDiscomfort();
      //Equipment Recommendations filter...
      this.filterEquipmentData();
      //Work Area Assessed filter...
      this.filterWorkArea();
      //Pending Request
      // this.getPendingRequestData();
    },
    filterWorkArea() {
      var _this = this;
      let config = {
        method: "post",
        url: process.env.VUE_APP_API_URL + "/api/work-area-assessed",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
        data: { request_data: this.encodeAPIRequest(_this.listConfig) },
      };
      _this
        .axios(config)
        .then(({ data }) => {
          if (data.success) {
            //Work Area Assessed...
            var decodedJson = _this.decodeAPIResponse(data.records.data);
            _this.workareadatacollection.labels = [];
            _this.workareadatacollection.datasets[0].data = [];
            _this.work_area_assessed_HomeCSV = decodedJson.work_area_assessed_HomeCSV;
            _this.work_area_assessed_LabCSV = decodedJson.work_area_assessed_LabCSV;
            _this.work_area_assessed_OfficeCSV = decodedJson.work_area_assessed_OfficeCSV;
            _this.work_area_assessed_AnywhereCSV = decodedJson.work_area_assessed_AnywhereCSV;
            _this.work_area_assessed = decodedJson.work_area_assessed;
            _this.work_area_assessed.forEach(function (item) {
              // _this.workareadatacollection.labels.push(item.name + " (" + item.percent + "%)");
              _this.workareadatacollection.labels.push(item.name);
              _this.workareadatacollection.datasets[0].data.push(parseInt(item.value.replaceAll(',','')));
            });
            _this.workareadatacollection.datasets[0].data.push(0);
          } else {
            _this.work_area_assessed_HomeCSV = [];
            _this.work_area_assessed_LabCSV = [];
            _this.work_area_assessed_OfficeCSV = [];
            _this.work_area_assessed_AnywhereCSV = [];
            _this.work_area_assessed = [];
            _this.workareadatacollection.labels = [];
            _this.workareadatacollection.datasets[0].data = [];
          }
          setTimeout(() => {
            if (_this.work_area_assessed.length) {
              _this.hideWorkAreaDownloadBtn = true;
            }
            else {
              _this.hideWorkAreaDownloadBtn = false;
            }
            _this.$refs.appendWorkArea.renderChart(
              _this.workareadatacollection,
              _this.chart_options
            );
          }, 100);
        })
        .catch(() => {
          // 
        });
    },
    filterActivityRegion() {
      var _this = this;
      let config = {
        method: "post",
        url: process.env.VUE_APP_API_URL + "/api/activity-by-region",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
        data: { request_data: this.encodeAPIRequest(_this.listConfig) },
      };
      _this
        .axios(config)
        .then(({ data }) => {
          if (data.success) {
            var decodedJson = _this.decodeAPIResponse(data.records.data);
            //Ergo Eval Activity by Region...
            _this.piechartdatacollection.labels = [];
            _this.piechartdatacollection.datasets[0].data = [];
            _this.piechartdatacollection.datasets[0].backgroundColor = [];
            _this.activity_region_CSV = decodedJson.activity_region_CSV;
            _this.activity_region = decodedJson.activity_region;
            _this.activity_region.forEach(function (item) {
              _this.piechartdatacollection.labels.push(item.name + " (" + item.percent + "%)");
              _this.piechartdatacollection.datasets[0].data.push(parseInt(item.value.replaceAll(',','')));
              _this.piechartdatacollection.datasets[0].backgroundColor.push(item.code);
            });
          } else {
            _this.activity_region_CSV = [];
            _this.activity_region = [];
            _this.piechartdatacollection.labels = [];
            _this.piechartdatacollection.datasets[0].data = [];
            _this.piechartdatacollection.datasets[0].backgroundColor = [];
          }
          setTimeout(() => {
            if (_this.activity_region.length) {
              _this.hideActivityRegionDownloadBtn = true;
            }
            else {
              _this.hideActivityRegionDownloadBtn = false;
            }
            _this.$refs.appendPieChart.renderChart(
              _this.piechartdatacollection,
              _this.chart_options
            );
          }, 100);
        })
        .catch(() => {
          // 
        });
    },
    filterSelfDiscomfort() {
      var _this = this;
      if (
        _this.listConfig.discomfort_edate != "" ||
        _this.listConfig.discomfort_regionBy != ""
      ) {
        _this.classDiscomfort = true;
      }
      let config = {
        method: "post",
        url: process.env.VUE_APP_API_URL + "/api/self-reported-discomfort",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
        data: { request_data: this.encodeAPIRequest(_this.listConfig) },
      };
      _this
        .axios(config)
        .then(({ data }) => {
          if (data.success) {
            //Self-Reported Discomfort...
            var decodedJson = _this.decodeAPIResponse(data.records.data);
            _this.selfdiscomfortdatacollection.labels = [];
            _this.selfdiscomfortdatacollection.datasets[0].data = [];
            _this.selfdiscomfortdatacollection.datasets[0].backgroundColor = [];
            _this.self_reported_discomfortCSV = decodedJson.self_reported_discomfortCSV;
            _this.self_reported_discomfort = decodedJson.self_reported_discomfort;
            _this.self_reported_discomfort.forEach(function (item) {
              // _this.selfdiscomfortdatacollection.labels.push(item.name + " (" + item.percent + "%)");
              _this.selfdiscomfortdatacollection.labels.push(item.name);
              _this.selfdiscomfortdatacollection.datasets[0].data.push(parseInt(item.value.replaceAll(',','')));
              _this.selfdiscomfortdatacollection.datasets[0].backgroundColor.push("#F3C695");
            });
            _this.selfdiscomfortdatacollection.datasets[0].data.push(0);
          } else {
            _this.self_reported_discomfortCSV = [];
            _this.self_reported_discomfort = [];
            _this.selfdiscomfortdatacollection.labels = [];
            _this.selfdiscomfortdatacollection.datasets[0].data = [];
            _this.selfdiscomfortdatacollection.datasets[0].backgroundColor = [];
          }
          setTimeout(() => {
            if (_this.self_reported_discomfort.length) {
              _this.hideSelfReportedDownloadBtn = true;
            }
            else {
              _this.hideSelfReportedDownloadBtn = false;
            }
            _this.setChartWidth = (_this.selfdiscomfortdatacollection.length < 2) ? _this.chart_options : _this.barChartOptions;
            _this.$refs.appendStandChart.renderChart(
              _this.selfdiscomfortdatacollection, _this.setChartWidth
            );
          }, 100);
        })
        .catch(() => {
          // 
        });
    },
    filterEquipmentData() {
      var _this = this;
      if (
        _this.listConfig.equipment_edate != "" ||
        _this.listConfig.equipment_regionBy != ""
      ) {
        _this.classEquipment = true;
      }
      let config = {
        method: "post",
        url: process.env.VUE_APP_API_URL + "/api/equipment-recommendation",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
        data: { request_data: _this.encodeAPIRequest(_this.listConfig) },
      };
      _this
        .axios(config)
        .then(({ data }) => {
          if (data.success) {
            //Equipment Recommendations...
            var decodedJson = _this.decodeAPIResponse(data.records.data);

            _this.equipmentdatacollection.labels = [];
            _this.equipmentdatacollection.datasets[0].data = [];
            _this.equipmentdatacollection.datasets[0].backgroundColor = [];
            _this.equipment_recommendationsCSV = decodedJson.equipment_recommendationsCSV;
            _this.equipment_recommendations = decodedJson.equipment_recommendations;
            _this.equipment_recommendations.forEach(function (item) {
              // _this.equipmentdatacollection.labels.push(item.name + " (" + item.percent + "%)");
              _this.equipmentdatacollection.labels.push(item.name);
              _this.equipmentdatacollection.datasets[0].data.push(parseInt(item.value.replaceAll(',','')));
              _this.equipmentdatacollection.datasets[0].backgroundColor.push("#F3C695");
            });
            _this.equipmentdatacollection.datasets[0].data.push(0);
          } else {
            _this.equipment_recommendationsCSV = [];
            _this.equipment_recommendations = [];
            _this.equipmentdatacollection.labels = [];
            _this.equipmentdatacollection.datasets[0].data = [];
            _this.equipmentdatacollection.datasets[0].backgroundColor = [];
          }
          setTimeout(() => {
            if (_this.equipment_recommendations.length) {
              _this.hideEquipmentDownloadBtn = true;
            }
            else {
              _this.hideEquipmentDownloadBtn = false;
            }
            _this.setChartWidth = (_this.equipment_recommendations.length < 2) ? _this.chart_options : _this.barChartOptions;
            _this.$refs.appendEquipment.renderChart(
              _this.equipmentdatacollection, _this.setChartWidth
            );
          }, 100);
        })
        .catch(() => {
          // 
        });
    },
    assignDefaultValues() {
      //Activity Region filter...
      this.listConfig.region_sdate = this.listConfig.start_date;
      this.listConfig.region_edate = this.listConfig.end_date;
      this.listConfig.region_regionBy = this.listConfig.regionBy;
      //Self-Reported Discomfort filter...
      this.listConfig.discomfort_sdate = this.listConfig.start_date;
      this.listConfig.discomfort_edate = this.listConfig.end_date;
      this.listConfig.discomfort_regionBy = this.listConfig.regionBy;
      //Equipment Recommendations filter...
      this.listConfig.equipment_sdate = this.listConfig.start_date;
      this.listConfig.equipment_edate = this.listConfig.end_date;
      this.listConfig.equipment_regionBy = this.listConfig.regionBy;
      //Work Area Assessed filter...
      this.listConfig.workArea_sdate = this.listConfig.start_date;
      this.listConfig.workArea_edate = this.listConfig.end_date;
      this.listConfig.workArea_regionBy = this.listConfig.regionBy;

      if (this.listConfig.end_date != "" && this.listConfig.end_date != null) {
        this.changeStartDateRegion();
        // this.changeStartDateDiscomfort();
        // this.changeStartDateEquipment();
        // this.changeStartDateWorkArea();
      }
    },
    updateDatePicker(comeFrom) {
      // Extend dataTables search
      $.fn.dataTable.ext.search.push(function (settings, data) {
        var min = $(".min-date-value").html();
        var max = $(".max-date-value").html();
        var createdAt = data[0] || 0; // Our date column in the table
        if (
          min == "" ||
          max == "" ||
          (moment(createdAt).isSameOrAfter(min) &&
            moment(createdAt).isSameOrBefore(max))
        ) {
          return true;
        }
        return false;
      });
      if (comeFrom == "Main") {
        this.assignDefaultValues();
        this.filterMainData();
      }
      if (comeFrom == "SelfDiscomfort") {
        this.filterSelfDiscomfort();
      }
      if (comeFrom == "EquipmentRecommendations") {
        this.filterEquipmentData();
      }
    },
    // changeStartDate() {
    //   this.$nextTick(() => {
    //     this.isDisabled = false;
    //     this.disabledDates = {
    //       to: new Date(this.listConfig.start_date - 8640000),
    //       from: new Date(Date.now()),
    //     };
    //     if (
    //       this.listConfig.end_date != "" &&
    //       this.listConfig.end_date != null
    //     ) {
    //       this.updateDatePicker("Main");
    //     }
    //   });
    // },
    changeStartDate(newStartDate) {
      this.$nextTick(() => {
        const startDate = new Date(newStartDate);
        const endDate = this.listConfig.end_date ? new Date(this.listConfig.end_date) : null;

        if (endDate && startDate > endDate) {
          this.$toast.error('"From" date cannot be greater than the "To" date.', {
              position: "top-right",
              duration: 5000,
            });
          this.listConfig.start_date = this.previousStartDate;
        } else {
          this.previousStartDate = newStartDate;

          // Old changeStartDate code
          this.isDisabled = false;
          this.disabledDates = {
            to: new Date(this.listConfig.start_date - 8640000),
            from: new Date(Date.now()),
          };
          if (this.listConfig.end_date != "" && this.listConfig.end_date != null) {
            this.updateDatePicker();
          }
        }
      });
    },
    changeEndDate() {
      this.$nextTick(() => {
        this.updateDatePicker("Main");
      });
    },
    changeStartDateWorkArea() {
      this.$nextTick(() => {
        this.isDisabledWorkArea = false;
        this.disabledDates = {
          to: new Date(this.listConfig.workArea_sdate - 8640000),
          from: new Date(Date.now()),
        };
        if (
          this.listConfig.workArea_edate != "" &&
          this.listConfig.workArea_edate != null
        ) {
          this.updateDatePicker("");
        }
      });
    },
    changeEndDateWorkArea() {
      this.$nextTick(() => {
        this.updateDatePicker("");
      });
    },
    changeStartDateRegion() {
      this.$nextTick(() => {
        this.isDisabledRegion = false;
        this.disabledDates = {
          to: new Date(this.listConfig.region_sdate - 8640000),
          from: new Date(Date.now()),
        };
        if (
          this.listConfig.region_edate != "" &&
          this.listConfig.region_edate != null
        ) {
          this.updateDatePicker("");
        }
      });
    },
    changeEndDateRegion() {
      this.$nextTick(() => {
        this.updateDatePicker("");
      });
    },
    changeStartDateDiscomfort() {
      this.$nextTick(() => {
        this.isDisabledDiscomfort = false;
        this.disabledDates = {
          to: new Date(this.listConfig.discomfort_sdate - 8640000),
          from: new Date(Date.now()),
        };
        if (
          this.listConfig.discomfort_edate != "" &&
          this.listConfig.discomfort_edate != null
        ) {
          this.updateDatePicker("SelfDiscomfort");
        }
      });
    },
    changeEndDateDiscomfort() {
      this.$nextTick(() => {
        this.updateDatePicker("SelfDiscomfort");
      });
    },
    changeStartDateEquipment() {
      this.$nextTick(() => {
        this.isDisabledEquipment = false;
        this.disabledDates = {
          to: new Date(this.listConfig.equipment_sdate - 8640000),
          from: new Date(Date.now()),
        };
        if (
          this.listConfig.equipment_edate != "" &&
          this.listConfig.equipment_edate != null
        ) {
          this.updateDatePicker("EquipmentRecommendations");
        }
      });
    },
    changeEndDateEquipment() {
      this.$nextTick(() => {
        this.updateDatePicker("EquipmentRecommendations");
      });
    },
    customFormatter(date) {
      return moment(date).format("MMM DD, YYYY");
    },
    showRequestDataSet(dataSet,userName) {
      if (localStorage.getItem("userToken")) {
        var _this = this;
        _this.$refs.datasetModal.$refs.Spinner.modalLoader = true;
        if (dataSet != null) {
          var i = 1;
          for (const item in dataSet) {
            dataSet[item].id = i++;
            if (dataSet[item].options) {
              for (const item1 in dataSet[item].options) {
                if (dataSet[item].options[item1].answer) {
                  if (dataSet[item].answer)
                    dataSet[item].answer += ', ' + dataSet[item].options[item1].answer;
                  else
                    dataSet[item].answer = dataSet[item].options[item1].answer;
                }
              }
            }
            if (dataSet[item].subquestions) {
              for (const item2 in dataSet[item].subquestions) {
                if (dataSet[item].subquestions[item2].answer) {
                  if (dataSet[item].answer) {
                    dataSet[item].answer += ', ' + dataSet[item].subquestions[item2].question + ' : ' + dataSet[item].subquestions[item2].answer;
                  }
                  else {
                    dataSet[item].answer = dataSet[item].subquestions[item2].question + ' : ' + dataSet[item].subquestions[item2].answer;
                  }
                }
              }
            }
          }
          this.dataSet = dataSet;
          _this.$refs.datasetModal.$refs.Spinner.modalLoader = false;
          this.isEmptyDataSet = Object.keys(dataSet).length === 0;
          this.downloadMessage = "dataset of 'Scheduled Requests' for '"+userName+"'" ;
        } else {
          this.dataSet = [];
          this.isEmptyDataSet = true;
          _this.$refs.datasetModal.$refs.Spinner.modalLoader = false;
        }
      } else {
        _this.$refs.datasetModal.$refs.Spinner.modalLoader = false;
        $('.modal-backdrop').remove();
        $(document.body).removeClass("modal-open");
        this.tokenExpired();
      }
    },
    getMitigationCostData() {
      this.$parent.$refs.fullpageloader.is_full_page_loader = true;
      var _this = this;
      _this.assignDefaultValues();
      _this.getPendingRequest();
      let config = {
        method: "post",
        url: process.env.VUE_APP_API_URL + "/api/dashboard-search",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
        data: { request_data: _this.encodeAPIRequest(_this.listConfig) },
      };
      _this
        .axios(config)
        .then(({ data }) => {
          if (data.success) {
            // console.log(data);
            var decodedJson = _this.decodeAPIResponse(data.records.data);
            this.classEquipment = true;
            this.classDiscomfort = true;
            _this.completed_ergo_eval = decodedJson.completed_ergo_eval;
            //Reported Discomfort by Equipment...
            _this.discomfort_by_equipment = decodedJson.discomfort_by_equipment;
            //Mitigation & Cost Savings...
            _this.mitigation_site_cost = decodedJson.mitigation_site_cost;
            _this.mitigation_site_count = decodedJson.mitigation_site_count;
            _this.mitigation_site_percent =
              decodedJson.mitigation_site_percent;
            _this.mitigation_selfAssessment_cost =
              decodedJson.mitigation_selfAssessment_cost;
            _this.mitigation_selfAssessment_count =
              decodedJson.mitigation_selfAssessment_count;
            _this.mitigation_selfAssessment_percent =
              decodedJson.mitigation_selfAssessment_percent;
            _this.mitigation_cost_saving = decodedJson.mitigation_cost_saving;
            _this.mitisitedatacollection.datasets[0].data = [];
            _this.mitisitedatacollection.datasets[0].data.push(_this.mitigation_site_percent);
            _this.mitisitedatacollection.datasets[0].data.push(100 - _this.mitigation_site_percent);
            _this.mitiselfdatacollection.datasets[0].data = [];
            _this.mitiselfdatacollection.datasets[0].data.push(_this.mitigation_selfAssessment_percent);
            _this.mitiselfdatacollection.datasets[0].data.push(100 - _this.mitigation_selfAssessment_percent);
            setTimeout(() => {
              //Mitigation & Cost Savings Site Eval...
              _this.$refs.appendMitiSite.renderChart(
                _this.mitisitedatacollection,
                _this.doughnut_chart_options
              );
              //Mitigation & Cost Savings Self-Assessment...
              _this.$refs.appendMitiSelf.renderChart(
                _this.mitiselfdatacollection,
                _this.doughnut_chart_options
              );
            }, 100);
          } else {
            console.log('no data found');
            _this.completed_ergo_eval = 0;
            _this.classEquipment = true;
            _this.classDiscomfort = true;
            //Reported Discomfort by Equipment...
            _this.discomfort_by_equipment = [];  
            //Mitigation & Cost Savings...
            _this.mitigation_site_cost = 0;
              _this.mitigation_site_count = 0;
            _this.mitigation_site_percent = 0;
            _this.mitigation_selfAssessment_cost = 0;
            _this.mitigation_selfAssessment_count = 0;
            _this.mitigation_selfAssessment_percent = 0;
            _this.mitigation_cost_saving = 0;
            _this.mitisitedatacollection.datasets[0].data = [0,100];
            _this.mitiselfdatacollection.datasets[0].data = [0,100];
            
            setTimeout(() => {
              //Mitigation & Cost Savings Site Eval...
              _this.$refs.appendMitiSite.renderChart(
                _this.mitisitedatacollection,_this.doughnut_chart_options
              );
              //Mitigation & Cost Savings Self-Assessment...
              _this.$refs.appendMitiSelf.renderChart(
                _this.mitiselfdatacollection,_this.doughnut_chart_options
              );
            }, 100);
          }
          this.$parent.$refs.fullpageloader.is_full_page_loader = false;
        })
        .catch(() => {
          // 
        });
    },
  },
};
</script>