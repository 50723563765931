<template>
  <div
    v-show="isPopupVisible"
    class="modal box_up info-popup"
    tabindex="-1"
    role="dialog"
    aria-labelledby="popupLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="popupLabel">{{ heading }}</h5>
          <button
            type="button"
            class="btn-close"
            @click="closePopup"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p v-html="message"></p> 
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="closePopup">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InfoPopup",
  props: {
    heading: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isPopupVisible: false,
    };
  },
  methods: {
    showPopup() {
      this.isPopupVisible = true;
    },
    closePopup() {
      this.isPopupVisible = false;
    },
  },
};
</script>

